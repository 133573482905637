import { useFetchTeam } from '@/store/team/hook'
import { usePagePic } from '@/store/main/hook'
import { Col, Image, Row } from 'antd'
import { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import './index.scss'
import SwiperCore, {
  Autoplay,
  Mousewheel,
  Navigation,
  Pagination,
} from 'swiper'

SwiperCore.use([Pagination, Navigation, Autoplay, Mousewheel])

const TeamPage = () => {
  const { fetchTeam, teamOutput: team } = useFetchTeam()
  const { fetchPagePic, pagePic } = usePagePic()

  useEffect(() => {
    fetchTeam()
  }, [fetchTeam])

  useEffect(() => {
    fetchPagePic()
  }, [fetchPagePic])

  return (
    <section className='team-page-container'>
      <section className='core'>
        <section className='core-content'>
          <h3 className='en-title'>{team.core.en_title}</h3>
          <p className='cn-title'>{team.core.cn_title}</p>
          <section className='desc'>
            <h3 className='title'>{team.core.desc.split('|')[0]}</h3>
            <p className='text'>{team.core.desc.split('|')[1]}</p>
            <p className='text'>{team.core.desc.split('|')[2]}</p>
          </section>
          <section className='list'>
            {team.core.list.map((item, index) => {
              const pic_key = index + 1
              return (
                <section className='item' key={item.id}>
                  <section className='content'>
                    <section className='icon'>
                      <img src={pagePic['core_' + pic_key]} alt='icon' />
                    </section>
                  </section>
                </section>
              )
            })}
          </section>
        </section>
      </section>
      <section className='group'>
        <section className='group-content'>
          <h3 className='en-title'>{team.group.en_title}</h3>
          <p className='cn-title'>{team.group.cn_title}</p>
          <Row className='list' gutter={16}>
            {team.group.list.map((item) => (
              <Col span={6} key={item.id}>
                <section className='content'>
                  <section className='icon'>
                    <img src={pagePic['team_' + item.id + '_1']} alt='icon' />
                  </section>

                  <section className='info'>
                    <section className='avatar'>
                      <img
                        src={pagePic['team_' + item.id + '_2']}
                        alt='avatar'
                      />
                    </section>
                    <section className='edu'>
                      {item.edu.map((text, index) => (
                        <p key={index}>{text}</p>
                      ))}
                    </section>
                  </section>
                  <section className='label'>
                    {item.label.map((text, index) => (
                      <p key={index}>{text}</p>
                    ))}
                  </section>
                </section>
              </Col>
            ))}
          </Row>
        </section>
      </section>
      <section className='adviser'>
        <section className='adviser-content'>
          <h3 className='en-title'>{team.adviser.en_title}</h3>
          <p className='cn-title'>{team.adviser.cn_title}</p>
          <Row className='list' gutter={16}>
            {team.adviser.list.map((item) => (
              <Col span={6} key={item.id}>
                <section className='content'>
                  <section className='icon'>
                    <section className='name'>{item.name}</section>
                    <img src={pagePic['adviser_' + item.id]} alt='icon' />
                  </section>
                  <section className='label'>
                    {item.text.map((text, index) => (
                      <p key={index}>{text}</p>
                    ))}
                  </section>
                </section>
              </Col>
            ))}
          </Row>
        </section>
      </section>
      <section
        className='cert'
        style={{ backgroundImage: `url(${pagePic.patent_bg})` }}
      >
        <section className='cert-content'>
          <h3 className='en-title'>{team.cert.en_title}</h3>
          <p className='cn-title'>{team.cert.cn_title}</p>
          {team.cert.list && team.cert.list.length > 0 && pagePic.patent_1 ? (
            <Swiper
              loop
              autoHeight
              slidesPerView={7}
              centeredSlides={true}
              watchSlidesProgress={true}
              mousewheel={true}
              onInit={(swiper) => {
                const slides = swiper.slides as any
                for (let i = 0; i < slides.length; i++) {
                  const slide = slides.eq(i)
                  const progress = slides[i].progress
                  slide.transform('scale(' + (1 - Math.abs(progress) / 5) + ')')
                }
              }}
              onSetTranslate={(swiper, translate) => {
                const slides = swiper.slides as any
                for (let i = 0; i < slides.length; i++) {
                  const slide = slides.eq(i)
                  const progress = slides[i].progress
                  slide.transform('scale(' + (1 - Math.abs(progress) / 5) + ')')
                }
              }}
              onSetTransition={(swiper, transition) => {
                const slides = swiper.slides as any

                for (var i = 0; i < slides.length; i++) {
                  var slide = slides.eq(i)
                  slide.transition(transition)
                }
              }}
            >
              {team.cert.list.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className='content'>
                    <Image
                      alt={pagePic['patent_' + item.id]}
                      src={pagePic['patent_' + item.id]}
                      style={{ width: '180px' }}
                      preview={{
                        mask: <span>预览</span>,
                      }}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : null}
        </section>
      </section>
      <section className='other'>
        <section className='other-content'>
          <h3 className='en-title'>{team.other.en_title}</h3>
          <p className='cn-title'>{team.other.cn_title}</p>
          <section className='aaa'>
            <h4 className='sub-title'>{team.other.szb_aaa.title}</h4>
            <Row className='list' justify='start' gutter={[14, 14]}>
              {team.other.szb_aaa.list.map((item, index) => {
                const pic_key = index + 1
                return (
                  <Col className='item' span={3} key={item.id}>
                    <Image
                      src={pagePic['cret_' + pic_key]}
                      preview={{
                        mask: <span>预览</span>,
                      }}
                    />
                  </Col>
                )
              })}
            </Row>
          </section>
          <section className='aaa'>
            <h4 className='sub-title'>{team.other.sd_aaa.title}</h4>
            <Row className='list' justify='start' gutter={14}>
              {team.other.sd_aaa.list.map((item, index) => {
                const pic_key = index + 14
                return (
                  <Col className='item' span={3} key={item.id}>
                    <Image
                      src={pagePic['cret_' + pic_key]}
                      preview={{
                        mask: <span>预览</span>,
                      }}
                    />
                  </Col>
                )
              })}
            </Row>
          </section>
        </section>
      </section>
    </section>
  )
}

export default TeamPage
