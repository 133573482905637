import { MainPageState } from '@/models/main'
import { bannerReducer } from './fetch-banner'
import { mainInfoReducer } from './fetch-info'
import { pagePicReducer } from './fetch-pic'
import { commonPicReducer } from './fetch-common'

const reducers = [
  mainInfoReducer,
  bannerReducer,
  pagePicReducer,
  commonPicReducer,
]

export default function mainReducer(state = {} as MainPageState, action) {
  switch (action.type) {
    default:
      break
  }
  return reducers.reduce((s, r) => r(s, action), state)
}
