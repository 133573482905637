import { Carousel, Image } from 'antd'
import { FC } from 'react'
import './index.scss'

type BannerState = {
  banner: { page_url: string; pic_url: string }[]
}
const Banner: FC<BannerState> = (props) => {
  const { banner } = props
  if (banner) {
    if (banner.length > 1) {
      return (
        <Carousel autoplay>
          {banner.map((item, index) => (
            <section className='img' key={index}>
              <Image className='banner' src={item.pic_url} preview={false} />
            </section>
          ))}
        </Carousel>
      )
    } else if (banner.length === 1) {
      return (
        <section>
          <img src={banner[0].pic_url} alt='banner' />
        </section>
      )
    }
  }

  return <section></section>
}

export default Banner
