import ApiUrl from '@/constants/api-url'
import { BannerItem, MainPageState } from '@/models/main'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootStore } from '../reducers'
import {
  FETCH_BANNER_BEGIN,
  FETCH_BANNER_FAILED,
  FETCH_BANNER_SUCCESS,
} from './constant'

const fetchBannerBeginAction = () => ({
  type: FETCH_BANNER_BEGIN,
})
const fetchBannerSuccessAction = (data: BannerItem[]) => ({
  type: FETCH_BANNER_SUCCESS,
  data,
})
const fetchBannerFailedAction = (data: { error: any }) => ({
  type: FETCH_BANNER_FAILED,
  data,
})
export const fetchBannerAsyncAction = (input: { alias: string }) => {
  return async (dispatch) => {
    dispatch(fetchBannerBeginAction())

    try {
      const { data } = await http.post(ApiUrl.Banner, input)

      dispatch(fetchBannerSuccessAction(data))
    } catch (error) {
      dispatch(fetchBannerFailedAction({ error }))
    }
  }
}

export function useBanner() {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { banner } = useSelector((state: RootStore) => state.main)

  const fetchBanner = useCallback(
    () =>
      dispatch(
        fetchBannerAsyncAction({ alias: pathname === '/' ? '/home' : pathname })
      ),
    [dispatch, pathname]
  )

  return {
    banner,
    fetchBanner,
  }
}

type ActionType = ReturnType<typeof fetchBannerSuccessAction> &
  ReturnType<typeof fetchBannerFailedAction>

export function bannerReducer(state = {} as MainPageState, action: ActionType) {
  switch (action.type) {
    case FETCH_BANNER_BEGIN:
      return {
        ...state,
        bannerPending: true,
        bannerError: null,
      }
    case FETCH_BANNER_SUCCESS:
      return {
        ...state,
        banner: action.data,
        bannerPending: false,
        bannerError: null,
      }
    case FETCH_BANNER_FAILED:
      return {
        ...state,
        bannerPending: false,
        bannerError: action.data.error,
      }
    default:
      return state
  }
}
