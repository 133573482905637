import { useFetchHome, useFetchHomeIndex } from '@/store/home/hook'
import { usePagePic } from '@/store/main/hook'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Carousel } from 'antd'
import { CarouselRef } from 'antd/lib/carousel'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import './index.scss'

const HomePage = () => {
  const { fetchHome, homeOutput } = useFetchHome()
  const { fetchHomeIndex, homeIndexOutput } = useFetchHomeIndex()
  const { fetchPagePic, pagePic } = usePagePic()
  const { push } = useHistory()
  const carouselRef = useRef<CarouselRef>(null)
  const [autoplay, setAutoPlay] = useState(true)

  useEffect(() => {
    fetchHome()
  }, [fetchHome])

  useEffect(() => {
    fetchHomeIndex()
  }, [fetchHomeIndex])

  useEffect(() => {
    fetchPagePic()
  }, [fetchPagePic])

  useEffect(() => {
    const timer = setInterval(() => {
      const list = document.querySelectorAll(
        '.video'
      ) as NodeListOf<HTMLVideoElement>
      const isPlayList: boolean[] = []
      list.forEach((item) => {
        isPlayList.push(!item.paused)
      })
      const isPlay = isPlayList.some((item) => item)
      setAutoPlay(!isPlay)
    }, 2000)

    return () => {
      clearInterval(timer)
    }
  }, [autoplay])

  return (
    <section className='home-page-container'>
      <section className='video-content'>
        <Carousel
          autoplaySpeed={3000}
          autoplay={autoplay}
          beforeChange={(from, to) => {
            const fromList = document.querySelectorAll(
              '#video-' + from
            ) as NodeListOf<HTMLVideoElement>
            fromList.forEach((item) => item.pause())
          }}
          dots={false}
          ref={carouselRef}
        >
          {homeIndexOutput.video_list.map((item, i) => (
            <section className='item' key={i}>
              <section
                className='left-icon'
                onClick={() => carouselRef.current?.prev()}
              >
                <LeftOutlined
                  // rev={undefined}
                  style={{ color: '#0E80D7', fontSize: '26px' }}
                />
              </section>
              <video
                className='video'
                id={'video-' + i}
                src={item.page_url}
                poster={item.pic_url}
                preload='auto'
                controls
              ></video>
              {/* <iframe
                title='intro'
                allowFullScreen
                width={1000}
                height={562}
                // src="https://player.bilibili.com/player.html?aid=277958301&bvid=BV15w411M74g&cid=1319060422&p=1"
                src="https://player.bilibili.com/player.html?aid=875400884&bvid=BV1mN4y167pX&cid=1319062443&p=1"
              ></iframe> */}
              <p className='poster-title'>{item.title}</p>
              <section
                className='right-icon'
                onClick={() => carouselRef.current?.next()}
              >
                <RightOutlined
                  // rev={undefined}
                  style={{ color: '#0E80D7', fontSize: '26px' }}
                />
              </section>
            </section>
          ))}
        </Carousel>
      </section>

      <section
        className='about-content'
        style={{ backgroundImage: `url(${pagePic.about})` }}
      >
        <section className='square'>
          <section className='intro'>
            <h3>关于深中邦</h3>
            <p className='intro-text'>{homeOutput.about.intro}</p>
            <Button type='primary' size='small' onClick={() => push('/about')}>
              查看更多
            </Button>
          </section>
          <section className='right-num'>
            {homeOutput.about.num.map((item) => {
              const text1 = item.split('|')[0]
              const text2 = item.split('|')[1]
              return (
                <section className='item' key={item}>
                  <span className='text1'>{text1}</span>
                  <span className='text2'>{text2}</span>
                </section>
              )
            })}
          </section>
        </section>
      </section>

      <section className='news-content'>
        <p className='news-title-en'>NEWS AND INFORMATION</p>
        <p className='news-title-cn'>新闻资讯</p>
        <section className='list-content'>
          {homeIndexOutput.news_list.splice(0, 1).map((item) => (
            <section
              className='left-content'
              key={item.id}
              onClick={() => push('/news/detail/?id=' + item.id)}
            >
              <img src={item.cover_pic} alt='cover' />
              <section className='info-content'>
                <section className='date'>
                  <span className='day'>{item.day}</span>
                  <span>{item.year_month}</span>
                </section>
                <section>
                  <h3 className='title'>{item.name}</h3>
                  <Paragraph
                    className='desc'
                    ellipsis={{
                      rows: 2,
                      expandable: false,
                    }}
                    title={item.description}
                  >
                    {item.description}
                  </Paragraph>
                </section>
              </section>
            </section>
          ))}
          <section className='right-content'>
            {homeIndexOutput.news_list.map((item) => (
              <section
                className='right-item'
                key={item.id}
                onClick={() => push('/news/detail/?id=' + item.id)}
              >
                <img src={item.cover_pic} alt='cover' />
                <section className='info-content'>
                  <h3 className='title'>{item.name}</h3>
                  <p className='date'>{item.date}</p>
                  <Paragraph
                    className='desc'
                    ellipsis={{
                      rows: 2,
                      expandable: false,
                    }}
                    title={item.description}
                  >
                    {item.description}
                  </Paragraph>
                </section>
              </section>
            ))}
          </section>
        </section>
      </section>
    </section>
  )
}

export default HomePage
