enum ApiUrl {
  MainInfo = '/static/api/main-info.json',
  Banner = '/www/home/banner',
  PagePic = '/www/home/page_pic',
  CommonPic = '/www/home/config',

  Home = '/static/api/home.json',
  HomeIndex = '/www/home/index',
  About = '/static/api/about.json',

  SteelPlateHome = '/static/api/steel-plate-home.json',
  SteelPlateList = '/www/home/steel_plate_apply_list',
  SteelPlateCateList = '/www/home/steel_plate_cate_list',
  SteelPlateDetail = '/www/home/steel_plate_apply_detail',

  ProdBase = '/static/api/prod-base.json',
  Team = '/static/api/team.json',
  Contact = '/static/api/contact.json',

  ProjectList = '/www/home/project_list',
  ProjectDetail = '/www/home/project_detail',

  NewsList = '/www/home/article_list',
  NewsDetail = '/www/home/article_detail',
}

export default ApiUrl
