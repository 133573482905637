import { Button, Col, Pagination, Row } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import './index.scss'
import {
  useFetchSteelPlateCateList,
  useFetchSteelPlateList,
} from '@/store/steel-plate/hook'

const SteelPlateListPage = () => {
  const { push } = useHistory()
  const {
    cateId,
    fetchSteelPlateList,
    steelPlateListOutput: { data: list, total, current_page, per_page },
  } = useFetchSteelPlateList()

  const { fetchSteelPlateCateList } = useFetchSteelPlateCateList()

  useEffect(() => {
    fetchSteelPlateList()
  }, [fetchSteelPlateList])

  useEffect(() => {
    fetchSteelPlateCateList()
  }, [fetchSteelPlateCateList])

  return (
    <section className='steel-plate-list-page-container'>
      <section className='list'>
        <Row className='list-content' gutter={[35, 35]}>
          {list.map((item, index) => {
            if (index === 0) {
              return (
                <Col
                  span={24}
                  onClick={() =>
                    push(
                      '/steel-plate/detail/?cateId=' +
                        cateId +
                        '&articleId=' +
                        item.id
                    )
                  }
                >
                  <Row className='item'>
                    <Col flex='0 0 450px'>
                      <img src={item.cover_pic} alt='cover' />
                    </Col>
                    <Col
                      flex='1'
                      style={{ backgroundColor: '#F5F6FA', padding: '40px' }}
                    >
                      <p className='name'>{item.name}</p>
                      <Paragraph className='desc' ellipsis={{ rows: 3 }}>
                        {item.description}
                      </Paragraph>
                      <Button>READ MORE</Button>
                    </Col>
                  </Row>
                </Col>
              )
            } else {
              return (
                <Col
                  span={8}
                  onClick={() =>
                    push(
                      '/steel-plate/detail/?cateId=' +
                        cateId +
                        '&articleId=' +
                        item.id
                    )
                  }
                >
                  <Row className='item'>
                    <Col span={24}>
                      <img src={item.cover_pic} alt='cover' />
                    </Col>
                    <Col>
                      <p className='name'>{item.name}</p>
                    </Col>
                    <Col>
                      <Paragraph className='desc' ellipsis={{ rows: 2 }}>
                        {item.description}
                      </Paragraph>
                    </Col>
                    <Col>
                      <Button>READ MORE</Button>
                    </Col>
                  </Row>
                </Col>
              )
            }
          })}
        </Row>
      </section>

      <section className='pagination-content'>
        <Row justify={'center'}>
          <Col>
            <Pagination
              total={total}
              current={current_page}
              pageSize={per_page}
              onChange={(page) => fetchSteelPlateList({ current_page: page })}
            />
          </Col>
        </Row>
      </section>
    </section>
  )
}

export default SteelPlateListPage
