import { NewsPageState } from '@/models/news'
import { fetchNewsDetailReducer } from './fetch-news-detail'
import { fetchNewsListReducer } from './fetch-news-list'

const reducers = [fetchNewsListReducer, fetchNewsDetailReducer]

export default function newsReducer(state = {} as NewsPageState, action) {
  switch (action.type) {
    default:
      break
  }
  return reducers.reduce((s, r) => r(s, action), state)
}
