import { History } from '@/models/about'
import { Col, Row } from 'antd'
import { FC } from 'react'

interface TimelineProps {
  data: History[]
  mode?: 'left' | 'middle' | 'right'
}

const Timeline: FC<TimelineProps> = (props) => {
  const { data, mode } = props

  return (
    <section className='timeline'>
      {data.map((item, index) => (
        <Row className='item' key={item.id} gutter={20}>
          <Col className='info' span={10}>
            {mode === 'left' || (mode === 'middle' && index % 2 === 0) ? (
              <section className='content'>
                <section className='course'>{item.course}</section>
                <section className='info-title'>{item.title}</section>
                <section className='intro'>{item.intro}</section>
                <section className='imgs'>
                  {item.pics.map((img) => (
                    <img src={img} alt='img' />
                  ))}
                </section>
              </section>
            ) : null}
          </Col>
          <Col className='year' span={4}>
            <section className='circle'>{item.year}</section>
            <section className='line'></section>
          </Col>
          <Col className='info' span={10}>
            {mode === 'left' || (mode === 'middle' && index % 2 === 1) ? (
              <section className='content'>
                <section className='course'>{item.course}</section>
                <section className='info-title'>{item.title}</section>
                <section className='intro'>{item.intro}</section>
                <section className='imgs'>
                  {item.pics.map((img) => (
                    <img src={img} alt='img' />
                  ))}
                </section>
              </section>
            ) : null}
          </Col>
        </Row>
      ))}
    </section>
  )
}

export default Timeline

Timeline.defaultProps = {
  mode: 'middle',
}
