import ApiUrl from '@/constants/api-url'
import { ProjectDetail, ProjectPageState } from '@/models/project'
import { useQuery } from '@/utils/hook'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_PROJECT_DETAIL_BEGIN,
  FETCH_PROJECT_DETAIL_FAILED,
  FETCH_PROJECT_DETAIL_SUCCESS,
} from './constant'

const fetchProjectDetailBeginAction = () => ({
  type: FETCH_PROJECT_DETAIL_BEGIN,
})
const fetchProjectDetailSuccessAction = (data: ProjectDetail) => ({
  type: FETCH_PROJECT_DETAIL_SUCCESS,
  data,
})
const fetchProjectDetailFailedAction = (data: { error: any }) => ({
  type: FETCH_PROJECT_DETAIL_FAILED,
  data,
})
export const fetchProjectDetailAsyncAction = (id) => {
  return async (dispatch) => {
    dispatch(fetchProjectDetailBeginAction())

    try {
      const { data } = await http.post(ApiUrl.ProjectDetail, { id })
      dispatch(fetchProjectDetailSuccessAction(data))
    } catch (error) {
      dispatch(fetchProjectDetailFailedAction({ error }))
    }
  }
}

export function useFetchProjectDetail() {
  const dispatch = useDispatch()
  const id = useQuery().get('id')
  const { projectDetail } = useSelector((state: RootStore) => state.project)

  const fetchProjectDetail = useCallback(
    () => dispatch(fetchProjectDetailAsyncAction(id)),
    [dispatch, id]
  )

  return {
    fetchProjectDetail,
    projectDetail,
  }
}

type ActionType = ReturnType<typeof fetchProjectDetailSuccessAction> &
  ReturnType<typeof fetchProjectDetailFailedAction>

export function fetchProjectDetailReducer(
  state = {} as ProjectPageState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_PROJECT_DETAIL_BEGIN:
      return {
        ...state,
        projectDetailPending: true,
        projectDetailError: null,
      }
    case FETCH_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        projectDetailPending: false,
        projectDetailError: null,
        projectDetail: action.data,
      }
    case FETCH_PROJECT_DETAIL_FAILED:
      return {
        ...state,
        projectDetailPending: false,
        projectDetailError: action.data.error,
      }
    default:
      return state
  }
}
