import { use3DRotate } from '@/utils/hook'
import { usePagePic } from '@/store/main/hook'
import { Image } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import './index.scss'
import {
  useFetchSteelPlateCateList,
  useFetchSteelPlateHome,
} from '@/store/steel-plate/hook'

const SteelPlateHomePage = () => {
  const { push } = useHistory()
  const [visible, setVisible] = useState(false)
  const { fetchSteelPlateHome, steelPlateHome } = useFetchSteelPlateHome()
  const { fetchSteelPlateCateList, steelPlateCateList } =
    useFetchSteelPlateCateList()
  const { fetchPagePic, pagePic } = usePagePic()

  const rotateElement = useRef(null)
  const rotate = use3DRotate(rotateElement.current)

  useEffect(() => {
    fetchSteelPlateHome()
  }, [fetchSteelPlateHome])

  useEffect(() => {
    fetchSteelPlateCateList()
  }, [fetchSteelPlateCateList])

  useEffect(() => {
    fetchPagePic()
  }, [fetchPagePic])

  return (
    <section className='steel-plate-home-page-container'>
      <section className='intro-content'>
        <section className='left'>
          <p className='en'>WEI STEEL PLATE</p>
          <h3 className='title'>威钢板</h3>
          <p className='desc'>{steelPlateHome.intro.text}</p>
        </section>
        <section
          className='right'
          ref={rotateElement}
          style={{
            transform: `rotateX(${rotate.x}deg) rotateY(${rotate.y}deg)`,
          }}
        >
          <img src={pagePic.plate_1} alt='banner' />
        </section>
      </section>
      <section className='adv-content'>
        <h3 className='en-title'>{steelPlateHome.advantage.en_title}</h3>
        <p className='cn-title'>{steelPlateHome.advantage.cn_title}</p>
        <p className='desc'>{steelPlateHome.advantage.desc}</p>
        <section className='list'>
          {steelPlateHome.advantage.list.map((item) => (
            <section className='item' key={item.id}>
              <section className='content'>
                <section className='icon'>
                  <img src={pagePic['adv_' + item.id]} alt='icon' />
                </section>
                <section className='intro'>
                  <h4 className='title'>{item.title}</h4>
                  <p className='text'>{item.text}</p>
                </section>
              </section>
            </section>
          ))}
        </section>
      </section>
      <section
        className='application-content'
        style={{
          backgroundImage: `url(${steelPlateHome.application.banner_bg})`,
        }}
      >
        <h3 className='en-title'>{steelPlateHome.application.en_title}</h3>
        <p className='cn-title'>{steelPlateHome.application.cn_title}</p>
        <p className='desc'>{steelPlateHome.application.desc}</p>
        <section className='list'>
          {steelPlateCateList.map((item) => (
            <section
              key={item.id}
              className='item'
              style={{ backgroundImage: `url(${item.pic_url})` }}
              onClick={() => {
                push('/steel-plate/list/?cateId=' + item.id)
              }}
            >
              <img src={item.icon_1} style={{ width: '70px' }} alt='icon' />
              <p className='text'>{item.name}</p>
            </section>
          ))}
        </section>
      </section>
      <section className='test-content'>
        <h3 className='en-title'>{steelPlateHome.test.en_title}</h3>
        <p className='cn-title'>{steelPlateHome.test.cn_title}</p>
        <p className='desc'>{steelPlateHome.test.desc}</p>
        <section className='list'>
          {steelPlateHome.test.list.map((item) => (
            <section className='item' key={item.id}>
              <section className='content'>
                <section className='icon'>
                  <img src={pagePic['test_' + item.id]} alt='icon' />
                </section>
                <p className='text'>{item.title}</p>
              </section>
            </section>
          ))}
        </section>
      </section>
      <section className='cert-content'>
        <h3 className='en-title'>{steelPlateHome.cert.en_title}</h3>
        <p className='cn-title'>{steelPlateHome.cert.cn_title}</p>
        <p className='desc'>{steelPlateHome.cert.desc}</p>
        <section className='list'>
          {steelPlateHome.cert.list.map((item) => (
            <section className='item' key={item.id}>
              <p className='text'>
                {item.title.split('|')[0]}
                <span className='special'>{item.title.split('|')[1]}</span>
              </p>
              <section className='imgs'>
                {item.cret_list.map((img, index) => {
                  const key = index + 1
                  return (
                    <Image
                      src={pagePic['cert_' + item.id + '_' + key]}
                      preview={{
                        mask: <span>预览</span>,
                      }}
                    />
                  )
                })}
              </section>

              <section style={{ display: 'none' }}>
                <Image.PreviewGroup
                  preview={{
                    visible,
                    onVisibleChange: (vis) => setVisible(vis),
                  }}
                >
                  <Image src='https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp' />
                  <Image src='https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp' />
                  <Image src='https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp' />
                </Image.PreviewGroup>
              </section>
            </section>
          ))}
        </section>
      </section>
    </section>
  )
}

export default SteelPlateHomePage
