import ApiUrl from '@/constants/api-url'
import { AboutOutput, AboutPageState } from '@/models/about'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_ABOUT_BEGIN,
  FETCH_ABOUT_FAILED,
  FETCH_ABOUT_SUCCESS,
} from './constant'

const fetchAboutBeginAction = () => ({
  type: FETCH_ABOUT_BEGIN,
})
const fetchAboutSuccessAction = (data: AboutOutput) => ({
  type: FETCH_ABOUT_SUCCESS,
  data,
})
const fetchAboutFailedAction = (data: { error: any }) => ({
  type: FETCH_ABOUT_FAILED,
  data,
})
export const fetchAboutAsyncAction = () => {
  return async (dispatch) => {
    dispatch(fetchAboutBeginAction())

    try {
      const { data } = await http.get(ApiUrl.About)
      dispatch(fetchAboutSuccessAction(data))
    } catch (error) {
      dispatch(fetchAboutFailedAction({ error }))
    }
  }
}

export function useFetchAbout() {
  const dispatch = useDispatch()
  const { aboutOutput } = useSelector((state: RootStore) => state.about)

  const fetchAbout = useCallback(
    () => dispatch(fetchAboutAsyncAction()),
    [dispatch]
  )

  return {
    aboutOutput,
    fetchAbout,
  }
}

type ActionType = ReturnType<typeof fetchAboutSuccessAction> &
  ReturnType<typeof fetchAboutFailedAction>

export function fetchAboutReducer(
  state = {} as AboutPageState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_ABOUT_BEGIN:
      return {
        ...state,
        aboutPending: true,
        aboutError: null,
      }
    case FETCH_ABOUT_SUCCESS:
      return {
        ...state,
        aboutOutput: action.data,
        aboutPending: false,
        aboutError: null,
      }
    case FETCH_ABOUT_FAILED:
      return {
        ...state,
        aboutPending: false,
        aboutError: action.data.error,
      }
    default:
      return state
  }
}
