import { ContactPageState } from '@/models/contact'
import { fetchContactReducer } from './fetch-contact'

const reducers = [fetchContactReducer]

export default function contactReducer(state = {} as ContactPageState, action) {
  switch (action.type) {
    default:
      break
  }
  return reducers.reduce((s, r) => r(s, action), state)
}
