import ApiUrl from '@/constants/api-url'
import { TeamOutput, TeamPageState } from '@/models/team'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_TEAM_BEGIN,
  FETCH_TEAM_FAILED,
  FETCH_TEAM_SUCCESS,
} from './constant'

const fetchTeamBeginAction = () => ({
  type: FETCH_TEAM_BEGIN,
})
const fetchTeamSuccessAction = (data: TeamOutput) => ({
  type: FETCH_TEAM_SUCCESS,
  data,
})
const fetchTeamFailedAction = (data: { error: any }) => ({
  type: FETCH_TEAM_FAILED,
  data,
})
export const fetchTeamAsyncAction = () => {
  return async (dispatch) => {
    dispatch(fetchTeamBeginAction())

    try {
      const { data } = await http.get(ApiUrl.Team)
      dispatch(fetchTeamSuccessAction(data))
    } catch (error) {
      dispatch(fetchTeamFailedAction({ error }))
    }
  }
}

export function useFetchTeam() {
  const dispatch = useDispatch()
  const { teamOutput } = useSelector((state: RootStore) => state.team)

  const fetchTeam = useCallback(
    () => dispatch(fetchTeamAsyncAction()),
    [dispatch]
  )

  return {
    teamOutput,
    fetchTeam,
  }
}

type ActionType = ReturnType<typeof fetchTeamSuccessAction> &
  ReturnType<typeof fetchTeamFailedAction>

export function fetchTeamReducer(
  state = {} as TeamPageState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_TEAM_BEGIN:
      return {
        ...state,
        teamPending: true,
        teamError: null,
      }
    case FETCH_TEAM_SUCCESS:
      return {
        ...state,
        teamOutput: action.data,
        teamPending: false,
        teamError: null,
      }
    case FETCH_TEAM_FAILED:
      return {
        ...state,
        teamPending: false,
        teamError: action.data.error,
      }
    default:
      return state
  }
}
