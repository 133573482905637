import ApiUrl from '@/constants/api-url'
import { ContactOutput, ContactPageState } from '@/models/contact'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_CONTACT_BEGIN,
  FETCH_CONTACT_FAILED,
  FETCH_CONTACT_SUCCESS,
} from './constant'

const fetchContactBeginAction = () => ({
  type: FETCH_CONTACT_BEGIN,
})
const fetchContactSuccessAction = (data: ContactOutput) => ({
  type: FETCH_CONTACT_SUCCESS,
  data,
})
const fetchContactFailedAction = (data: { error: any }) => ({
  type: FETCH_CONTACT_FAILED,
  data,
})
export const fetchContactAsyncAction = () => {
  return async (dispatch) => {
    dispatch(fetchContactBeginAction())

    try {
      const { data } = await http.get(ApiUrl.Contact)
      dispatch(fetchContactSuccessAction(data))
    } catch (error) {
      dispatch(fetchContactFailedAction({ error }))
    }
  }
}

export function useFetchContact() {
  const dispatch = useDispatch()
  const { contactOutput } = useSelector((state: RootStore) => state.contact)

  const fetchContact = useCallback(
    () => dispatch(fetchContactAsyncAction()),
    [dispatch]
  )

  return {
    contactOutput,
    fetchContact,
  }
}

type ActionType = ReturnType<typeof fetchContactSuccessAction> &
  ReturnType<typeof fetchContactFailedAction>

export function fetchContactReducer(
  state = {} as ContactPageState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_CONTACT_BEGIN:
      return {
        ...state,
        contactPending: true,
        contactError: null,
      }
    case FETCH_CONTACT_SUCCESS:
      return {
        ...state,
        contactOutput: action.data,
        contactPending: false,
        contactError: null,
      }
    case FETCH_CONTACT_FAILED:
      return {
        ...state,
        contactPending: false,
        contactError: action.data.error,
      }
    default:
      return state
  }
}
