import { Col, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import './layout.scss'
import {
  useFetchSteelPlateCateList,
  useFetchSteelPlateList,
} from '@/store/steel-plate/hook'

const SteelPlateLayout = (props) => {
  const { push } = useHistory()
  const { cateId } = useFetchSteelPlateList()
  const { steelPlateCateList } = useFetchSteelPlateCateList()

  return (
    <section className='steel-plate-layout-container'>
      <section className='cate-list'>
        <Row className='cate-content' gutter={22}>
          {steelPlateCateList.map((item) => (
            <Col span={4}>
              <section
                className={`item ${item.id === cateId ? 'active' : ''}`}
                onClick={() => push('/steel-plate/list/?cateId=' + item.id)}
              >
                <img className='img normal' src={item.icon_1} alt='icon' />
                <img className='img selected' src={item.icon_2} alt='icon' />
                <span className='name'>{item.name}</span>
              </section>
            </Col>
          ))}
        </Row>
      </section>
      {props.children}
    </section>
  )
}

export default SteelPlateLayout
