import { useFetchNewsList } from '@/store/news/hook'
import { Col, Pagination, Row } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import './index.scss'

const NewsListPage = () => {
  const { push } = useHistory()
  const {
    params,
    setParams,
    fetchNewsList,
    newsListOutput: { data: list, total },
  } = useFetchNewsList()

  useEffect(() => {
    fetchNewsList()
  }, [fetchNewsList])

  return (
    <section className='news-list-page-container'>
      <section className='list'>
        <Row className='list-content'>
          {list.map((item) => (
            <Col
              span={24}
              className='item'
              onClick={() => push(`news/detail/?id=${item.id}`)}
            >
              <Row align={'middle'}>
                <Col>
                  <Row justify={'center'} gutter={[0, 10]}>
                    <Col span={12}>
                      <p className='day'>{item.day}</p>
                    </Col>
                    <Col span={16}>
                      <p className='month'>{item.month}月</p>
                    </Col>
                  </Row>
                </Col>
                <Col flex='0 0 200px' push={1}>
                  <img src={item.cover_pic} alt='cover' />
                </Col>
                <Col flex='1' push={2}>
                  <Row gutter={[0, 20]}>
                    <Col span={24}>
                      <p className='name'>{item.name}</p>
                    </Col>
                    <Col span={24}>
                      <Paragraph
                        className='desc'
                        ellipsis={{
                          rows: 3,
                          expandable: false,
                        }}
                      >
                        {item.description}
                      </Paragraph>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </section>

      <section className='pagination-content'>
        <Row justify={'center'}>
          <Col>
            <Pagination
              total={total}
              current={params.current_page}
              pageSize={params.per_page}
              onChange={(page) => setParams({ ...params, current_page: page })}
            />
          </Col>
        </Row>
      </section>
    </section>
  )
}

export default NewsListPage
