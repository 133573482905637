import { SteelPlateState } from '@/models/steel-plate'
import { fetchSteelPlateCateListReducer } from './fetch-steel-plate-cate-list'
import { fetchSteelPlateDetailReducer } from './fetch-steel-plate-detail'
import { fetchSteelPlateHomeReducer } from './fetch-steel-plate-home'
import { fetchSteelPlateListReducer } from './fetch-steel-plate-list'

const reducers = [
  fetchSteelPlateHomeReducer,
  fetchSteelPlateListReducer,
  fetchSteelPlateCateListReducer,
  fetchSteelPlateDetailReducer,
]

export default function steelPlateReducer(
  state = {} as SteelPlateState,
  action
) {
  switch (action.type) {
    default:
      break
  }
  return reducers.reduce((s, r) => r(s, action), state)
}
