import { MenuItem } from '@/models/main'
import { RootStore } from '@/store/reducers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

export const useMatchRoute = () => {
  const { push, location } = useHistory()
  const { mainInfo } = useSelector((state: RootStore) => state.main)
  const [parentKey, setParentKey] = useState<string>()
  const [childKey, setChildKey] = useState<string>()

  /**
   * 递归匹配当前路由的下的菜单并设置，刷新页面则会保存Menu状态
   */
  const matchMenuRoute = useCallback(() => {
    var stop = false // 找到子key终止循环
    const findKey = (m: MenuItem) => {
      if (location.pathname === '/' && m.pageUrl === '/home') {
        // /和/home都为首页
        setChildKey(m.pageUrl ? m.id : '')
        return
      }

      if (location.pathname.includes(m.pageUrl) && m.children.length > 0) {
        setParentKey(m.pageUrl ? m.id : '')
        m.children.forEach(findKey)
      } else if (location.pathname.includes(m.pageUrl)) {
        if (!stop) {
          setChildKey(m.pageUrl ? m.id : '')
          stop = true
        }
        return
      }
    }

    mainInfo.menu.forEach(findKey)
  }, [location.pathname, mainInfo.menu])

  useEffect(() => {
    matchMenuRoute()
  }, [matchMenuRoute])

  return {
    push,
    parentKey,
    childKey,
  }
}

export const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const useMatchQuery = (key: string, value: string) => {
  const query = useQuery()

  return useMemo(() => query.get(key) === value, [key, query, value])
}

export const use3DRotate = (element: HTMLElement | null) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const multiple = 20

  const transformElement = useCallback(
    (x: number, y: number) => {
      if (!element) {
        return {
          calcX: 0,
          calcY: 0,
        }
      }

      const box = element.getBoundingClientRect()
      const calcX = -(1.2 * y - box.y - box.height / 2) / multiple
      const calcY = (x - box.x - box.width / 2) / multiple

      return {
        calcX,
        calcY,
      }
    },
    [element]
  )

  const mouseMoveHandle = useCallback(
    (e: MouseEvent) => {
      const { calcX, calcY } = transformElement(e.clientX, e.clientY)
      setPosition({ x: calcX, y: calcY })
    },
    [transformElement]
  )

  const mouseLeaveHandle = useCallback(() => {
    setPosition({ x: 0, y: 0 })
  }, [])

  useEffect(() => {
    if (element) {
      element.addEventListener('mousemove', mouseMoveHandle)
      element.addEventListener('mouseleave', mouseLeaveHandle)
    }

    return () => {
      if (element) {
        element.removeEventListener('mousemove', mouseMoveHandle)
        element.removeEventListener('mouseleave', mouseLeaveHandle)
      }
    }
  }, [element, mouseLeaveHandle, mouseMoveHandle])

  return position
}
