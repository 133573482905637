import { TeamPageState } from '@/models/team'
import { fetchTeamReducer } from './fetch-team'

const reducers = [fetchTeamReducer]

export default function teamReducer(state = {} as TeamPageState, action) {
  switch (action.type) {
    default:
      break
  }
  return reducers.reduce((s, r) => r(s, action), state)
}
