import { useFetchProjectList } from '@/store/project/hook'
import { Button, Col, Pagination, Row } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import './index.scss'

const ProjectListPage = () => {
  const { push } = useHistory()
  const {
    params,
    setParams,
    fetchProjectList,
    projectListOutput: { data: list, total },
  } = useFetchProjectList()

  useEffect(() => {
    fetchProjectList()
  }, [fetchProjectList])

  return (
    <section className='project-list-page-container'>
      <section className='list'>
        <Row className='list-content' gutter={[35, 35]}>
          {list.map((item, index) => {
            if (index === 0) {
              return (
                <Col
                  span={24}
                  onClick={() => push(`project/detail/?id=${item.id}`)}
                >
                  <Row className='item'>
                    <Col flex='0 0 450px'>
                      <img src={item.cover_pic} alt='cover' />
                    </Col>
                    <Col
                      flex='1'
                      style={{ backgroundColor: '#F5F6FA', padding: '40px' }}
                    >
                      <p className='name'>{item.name}</p>
                      <Paragraph className='desc' ellipsis={{ rows: 3 }}>
                        {item.description}
                      </Paragraph>
                      <Button>READ MORE</Button>
                    </Col>
                  </Row>
                </Col>
              )
            } else {
              return (
                <Col
                  span={8}
                  onClick={() => push(`project/detail/?id=${item.id}`)}
                >
                  <Row className='item'>
                    <Col span={24}>
                      <img src={item.cover_pic} alt='cover' />
                    </Col>
                    <Col>
                      <p className='name'>{item.name}</p>
                    </Col>
                    <Col>
                      <Paragraph className='desc' ellipsis={{ rows: 2 }}>
                        {item.description}
                      </Paragraph>
                    </Col>
                    <Col>
                      <Button>READ MORE</Button>
                    </Col>
                  </Row>
                </Col>
              )
            }
          })}
        </Row>
      </section>

      <section className='pagination-content'>
        <Row justify={'center'}>
          <Col>
            <Pagination
              total={total}
              current={params.current_page}
              pageSize={params.per_page}
              onChange={(page) => setParams({ ...params, current_page: page })}
            />
          </Col>
        </Row>
      </section>
    </section>
  )
}

export default ProjectListPage
