import { RootStore } from './reducers'

const initialState: RootStore = {
  demo: {
    num: 0,
  },
  main: {
    mainInfoError: null,
    mainInfoPending: false,
    mainInfo: {
      addr: '',
      beian: '',
      groupname: '',
      logo: '',
      logoWhite: '',
      menu: [],
      qrcode: '',
      tel: [],
      zipcode: '',
    },
    bannerError: null,
    bannerPending: false,
    banner: [],
    pagePicError: null,
    pagePicPending: false,
    pagePic: {
      about: '',
      about_1: '',
      about_2: '',
      about_circle: '',
      plate_1: '',
      adv_1: '',
      adv_2: '',
      adv_3: '',
      adv_4: '',
      adv_5: '',
      adv_6: '',
      adv_7: '',
      adv_8: '',
      adv_9: '',
      test_1: '',
      test_2: '',
      test_3: '',
      test_4: '',
      test_5: '',
      test_6: '',
      test_7: '',
      test_8: '',
      cert_1_1: '',
      cert_1_2: '',
      cert_1_3: '',
      cert_2_1: '',
      cert_2_2: '',
      cert_2_3: '',
      cert_2_4: '',
      cert_3_1: '',
      cert_3_2: '',
      cert_3_3: '',
      cert_3_4: '',
      cert_4_1: '',
      cert_4_2: '',
      cert_4_3: '',
      cert_4_4: '',
      cert_4_5: '',
      cert_4_6: '',
      cert_4_7: '',
      cert_5_1: '',
      cert_5_2: '',
      cert_5_3: '',
      cert_5_4: '',
      cert_6_1: '',
      cert_6_2: '',
      cert_6_3: '',
      cert_7_1: '',
      cert_7_2: '',
      cert_7_3: '',
      cert_7_4: '',
      cert_8_1: '',
      cert_8_2: '',
      cert_8_3: '',
      cert_8_4: '',
      cert_9_1: '',
      cert_9_2: '',
      cert_9_3: '',
      cert_10_1: '',
      cert_10_2: '',
      cert_10_3: '',
      cert_11_1: '',
      cert_11_2: '',
      cert_11_3: '',
      cert_11_4: '',
      zj_intro_1: '',
      zj_intro_2: '',
      zj_intro_3: '',
      zj_intro_4: '',
      zj_intro_5: '',
      zj_intro_6: '',
      core_1: '',
      core_2: '',
      core_3: '',
      core_4: '',
      core_5: '',
      core_6: '',
      core_7: '',
      core_8: '',
      core_9: '',
      core_10: '',
      core_11: '',
      core_12: '',
      team_1_1: '',
      team_1_2: '',
      team_2_1: '',
      team_2_2: '',
      team_3_1: '',
      team_3_2: '',
      team_4_1: '',
      team_4_2: '',
      adviser_1: '',
      adviser_2: '',
      adviser_3: '',
      patent_1: '',
      patent_2: '',
      patent_3: '',
      patent_4: '',
      patent_5: '',
      patent_6: '',
      patent_7: '',
      patent_8: '',
      patent_9: '',
      patent_10: '',
      cret_1: '',
      cret_2: '',
      cret_3: '',
      cret_4: '',
      cret_5: '',
      cret_6: '',
      cret_7: '',
      cret_8: '',
      cret_9: '',
      cret_10: '',
      cret_11: '',
      cret_12: '',
      cret_13: '',
      cret_14: '',
      cret_15: '',
      cret_16: '',
      cret_17: '',
      cret_18: '',
      cret_19: '',
      cret_20: '',
      tel_logo: '',
      qq_logo: '',
      email_logo: '',
      addr_logo: '',
      patent_bg: '',
    },
    commonPicError: null,
    commonPicPending: false,
    commonPic: {
      logo_full: '',
      tiktok_qrcode: '',
      kwai_qrcode: '',
      wechat_channel_qrcode: '',
      wechat_mp_qrcode: '',
      qrcode: '',
    },
  },
  home: {
    homeError: null,
    homePending: false,
    homeOutput: {
      news: [],
      video_poster: '',
      about: {
        poster: '',
        intro: '',
        num: [],
      },
    },
    homeIndexError: null,
    homeIndexPending: false,
    homeIndexOutput: {
      news_list: [],
      video_list: [],
    },
  },
  about: {
    aboutError: null,
    aboutPending: false,
    aboutOutput: {
      intro: [],
      core: {
        intro: '',
        num: [],
      },
      culture: {
        bg: '',
        intro: [],
      },
      history: [],
    },
  },
  steelPlate: {
    steelPlateHomeError: null,
    steelPlateHomePending: false,
    steelPlateHome: {
      advantage: {
        cn_title: '',
        en_title: '',
        desc: '',
        list: [],
      },
      application: {
        cn_title: '',
        en_title: '',
        desc: '',
        banner_bg: '',
        list: [],
      },
      test: {
        cn_title: '',
        en_title: '',
        desc: '',
        list: [],
      },
      intro: {
        text: '',
        banner: '',
      },
      cert: {
        cn_title: '',
        en_title: '',
        desc: '',
        list: [],
      },
    },

    steelPlateListError: null,
    steelPlateListPending: false,
    steelPlateListOutput: {
      data: [],
      current_page: 0,
      per_page: 0,
      last_page: 0,
      total: 0,
    },

    steelPlateDetailError: null,
    steelPlateDetailPending: false,
    steelPlateDetail: {
      id: 0,
      detail: '',
      date: '',
      name: '',
    },

    steelPlateCateListError: null,
    steelPlateCateListPending: false,
    steelPlateCateList: [],
  },
  prodBase: {
    prodBaseError: null,
    prodBasePending: false,
    prodBaseOutput: {
      zb: {
        cn_title: '',
        en_title: '',
        address: [],
        base_imgs: [],
        intro: [],
      },
      zj: {
        cn_title: '',
        en_title: '',
        address: [],
        base_imgs: [],
        intro: [],
      },
    },
  },
  team: {
    teamError: null,
    teamPending: false,
    teamOutput: {
      adviser: {
        cn_title: '',
        en_title: '',
        background: '',
        desc: '',
        list: [],
      },
      cert: {
        cn_title: '',
        en_title: '',
        background: '',
        desc: '',
        list: [],
      },
      core: {
        cn_title: '',
        en_title: '',
        background: '',
        desc: '',
        list: [],
      },
      group: {
        cn_title: '',
        en_title: '',
        background: '',
        desc: '',
        list: [],
      },
      other: {
        cn_title: '',
        en_title: '',
        background: '',
        desc: '',
        sd_aaa: {
          title: '',
          list: [],
        },
        szb_aaa: {
          title: '',
          list: [],
        },
        iso: {
          title: '',
          list: [],
        },
      },
    },
  },
  contact: {
    contactError: null,
    contactPending: false,
    contactOutput: {
      company: {
        cn_title: '',
        en_title: '',
        addr_bg: '',
      },
      contact_way: {
        cn_title: '',
        en_title: '',
        list: [],
      },
    },
  },
  project: {
    projectListError: null,
    projectListPending: false,
    projectListOutput: {
      data: [],
      current_page: 0,
      per_page: 0,
      last_page: 0,
      total: 0,
    },

    projectDetailError: null,
    projectDetailPending: false,
    projectDetail: {
      id: 0,
      detail: '',
      date: '',
      name: '',
    },
  },
  news: {
    newsListError: null,
    newsListPending: false,
    newsListOutput: {
      data: [],
      current_page: 0,
      per_page: 0,
      last_page: 0,
      total: 0,
    },

    newsDetailError: null,
    newsDetailPending: false,
    newsDetail: {
      id: 0,
      detail: '',
      date: '',
      name: '',
      views: 0,
    },
  },
}

export default initialState
