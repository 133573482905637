import { ProjectPageState } from '@/models/project'
import { fetchProjectDetailReducer } from './fetch-project-detail'
import { fetchProjectListReducer } from './fetch-project-list'

const reducers = [fetchProjectListReducer, fetchProjectDetailReducer]

export default function projectReducer(state = {} as ProjectPageState, action) {
  switch (action.type) {
    default:
      break
  }
  return reducers.reduce((s, r) => r(s, action), state)
}
