import Banner from '@/components/banner'
import FabButton from '@/components/fab-btn'
import Footer from '@/components/layout/footer'
import Header from '@/components/layout/header'
import { useBanner, useMainInfo, useCommonPic } from '@/store/main/hook'
import { Layout } from 'antd'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import './index.scss'

const { Content } = Layout

const MainLayout = (props) => {
  const { fetchMainInfo } = useMainInfo()
  const { fetchBanner, banner } = useBanner()
  const { pathname } = useLocation()
  const { fetchCommonPic, commonPic } = useCommonPic()

  useEffect(() => {
    fetchMainInfo()
  }, [fetchMainInfo])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [pathname])

  useEffect(() => {
    fetchBanner()
  }, [fetchBanner])

  useEffect(() => {
    fetchCommonPic()
  }, [fetchCommonPic])

  return (
    <Layout className='site-layout-container'>
      <Layout className='site-layout-content'>
        <Header />
        <FabButton commonPic={commonPic} />
        <SwitchTransition>
          <CSSTransition
            key={window.location.pathname}
            timeout={500}
            classNames='animate'
          >
            <section>
              <Content
                className='site-content'
                style={{
                  margin: 0,
                  padding: 0,
                  minHeight: 'calc(100vh - 426px)',
                }}
              >
                <Banner banner={banner} />
                {props.children}
              </Content>
              <Footer />
            </section>
          </CSSTransition>
        </SwitchTransition>
      </Layout>
    </Layout>
  )
}

export default MainLayout
