import ApiUrl from '@/constants/api-url'
import { HomeOutput, HomePageState } from '@/models/home'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_HOME_BEGIN,
  FETCH_HOME_FAILED,
  FETCH_HOME_SUCCESS,
} from './constant'

const fetchHomeBeginAction = () => ({
  type: FETCH_HOME_BEGIN,
})
const fetchHomeSuccessAction = (data: HomeOutput) => ({
  type: FETCH_HOME_SUCCESS,
  data,
})
const fetchHomeFailedAction = (data: { error: any }) => ({
  type: FETCH_HOME_FAILED,
  data,
})
export const fetchHomeAsyncAction = () => {
  return async (dispatch) => {
    dispatch(fetchHomeBeginAction())

    try {
      const { data } = await http.get(ApiUrl.Home)
      dispatch(fetchHomeSuccessAction(data))
    } catch (error) {
      dispatch(fetchHomeFailedAction({ error }))
    }
  }
}

export function useFetchHome() {
  const dispatch = useDispatch()
  const { homeOutput } = useSelector((state: RootStore) => state.home)

  const fetchHome = useCallback(
    () => dispatch(fetchHomeAsyncAction()),
    [dispatch]
  )

  return {
    homeOutput,
    fetchHome,
  }
}

type ActionType = ReturnType<typeof fetchHomeSuccessAction> &
  ReturnType<typeof fetchHomeFailedAction>

export function fetchHomeReducer(
  state = {} as HomePageState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_HOME_BEGIN:
      return {
        ...state,
        homePending: true,
        homeError: null,
      }
    case FETCH_HOME_SUCCESS:
      return {
        ...state,
        homeOutput: action.data,
        homePending: false,
        homeError: null,
      }
    case FETCH_HOME_FAILED:
      return {
        ...state,
        homePending: false,
        homeError: action.data.error,
      }
    default:
      return state
  }
}
