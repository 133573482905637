import { HomePageState } from '@/models/home'
import { fetchHomeReducer } from './fetch-home'
import { fetchHomeIndexReducer } from './fetch-home-index'

const reducers = [fetchHomeReducer, fetchHomeIndexReducer]

export default function homeReducer(state = {} as HomePageState, action) {
  switch (action.type) {
    default:
      break
  }
  return reducers.reduce((s, r) => r(s, action), state)
}
