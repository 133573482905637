import ApiUrl from '@/constants/api-url'
import { PagerInput } from '@/models/common'
import { ProjectListOutput, ProjectPageState } from '@/models/project'
import http from '@/utils/http'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_PROJECT_LIST_BEGIN,
  FETCH_PROJECT_LIST_FAILED,
  FETCH_PROJECT_LIST_SUCCESS,
} from './constant'

const fetchProjectListBeginAction = () => ({
  type: FETCH_PROJECT_LIST_BEGIN,
})
const fetchProjectListSuccessAction = (data: ProjectListOutput) => ({
  type: FETCH_PROJECT_LIST_SUCCESS,
  data,
})
const fetchProjectListFailedAction = (data: { error: any }) => ({
  type: FETCH_PROJECT_LIST_FAILED,
  data,
})
export const fetchProjectListAsyncAction = (params: PagerInput) => {
  return async (dispatch) => {
    dispatch(fetchProjectListBeginAction())

    try {
      const { data } = await http.get(ApiUrl.ProjectList, { params })
      dispatch(fetchProjectListSuccessAction(data))
    } catch (error) {
      dispatch(fetchProjectListFailedAction({ error }))
    }
  }
}

export function useFetchProjectList() {
  const dispatch = useDispatch()
  const [params, setParams] = useState<PagerInput>({
    current_page: 1,
    per_page: 7,
  })
  const { projectListOutput } = useSelector((state: RootStore) => state.project)

  const fetchProjectList = useCallback(
    () => dispatch(fetchProjectListAsyncAction(params)),
    [dispatch, params]
  )

  return {
    projectListOutput,
    fetchProjectList,
    params,
    setParams,
  }
}

type ActionType = ReturnType<typeof fetchProjectListSuccessAction> &
  ReturnType<typeof fetchProjectListFailedAction>

export function fetchProjectListReducer(
  state = {} as ProjectPageState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_PROJECT_LIST_BEGIN:
      return {
        ...state,
        projectListPending: true,
        projectListError: null,
      }
    case FETCH_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        projectListPending: false,
        projectListError: null,
        projectListOutput: action.data,
      }
    case FETCH_PROJECT_LIST_FAILED:
      return {
        ...state,
        projectListPending: false,
        projectListError: action.data.error,
      }
    default:
      return state
  }
}
