import { RouteItem } from '@/models/route'
import { Route, Router, Switch } from 'react-router-dom'
import history from './history'
import routes from './router-config'

function renderRouter(routes: RouteItem[], contextPath: string) {
  const children: any[] = []

  const renderRoute = (item: RouteItem, routeContextPath: string) => {
    // 完整的路径则返回，否则自动加上
    let newContextPath =
      /^\//.test(item.path) || item.path === '*'
        ? item.path
        : `${routeContextPath}/${item.path}`
    newContextPath = newContextPath.replace(/\/+/g, '/')

    if (item.component && item.childRoutes) {
      const childRoutes = renderRouter(item.childRoutes, newContextPath)
      children.push(
        <Route
          key={newContextPath}
          render={(props) => (
            <item.component {...props}>{childRoutes}</item.component>
          )}
          path={newContextPath}
        />
      )
    } else if (item.component) {
      children.push(
        <Route
          key={newContextPath}
          component={item.component}
          path={newContextPath}
          exact
        />
      )
    } else if (item.childRoutes) {
      item.childRoutes.forEach((r) => renderRoute(r, newContextPath))
    }
  }

  routes.forEach((item) => renderRoute(item, contextPath))

  return <Switch>{children}</Switch>
}

function RootRouter() {
  const children = renderRouter(routes, '/')

  return <Router history={history}>{children}</Router>
}

export default RootRouter
