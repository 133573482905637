import ApiUrl from '@/constants/api-url'
import { PagePicState, MainPageState } from '@/models/main'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootStore } from '../reducers'
import {
  FETCH_PAGE_PIC_BEGIN,
  FETCH_PAGE_PIC_FAILED,
  FETCH_PAGE_PIC_SUCCESS,
} from './constant'

const fetchPagePicBeginAction = () => ({
  type: FETCH_PAGE_PIC_BEGIN,
})
const fetchPagePicSuccessAction = (data: PagePicState) => ({
  type: FETCH_PAGE_PIC_SUCCESS,
  data,
})
const fetchPagePicFailedAction = (data: { error: any }) => ({
  type: FETCH_PAGE_PIC_FAILED,
  data,
})
export const fetchPagePicAsyncAction = (input: { alias: string }) => {
  return async (dispatch) => {
    dispatch(fetchPagePicBeginAction())

    try {
      const { data } = await http.post(ApiUrl.PagePic, input)

      dispatch(fetchPagePicSuccessAction(data))
    } catch (error) {
      dispatch(fetchPagePicFailedAction({ error }))
    }
  }
}

export function usePagePic() {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { pagePic } = useSelector((state: RootStore) => state.main)

  const fetchPagePic = useCallback(
    () =>
      dispatch(
        fetchPagePicAsyncAction({
          alias: pathname === '/' ? '/home' : pathname,
        })
      ),
    [dispatch, pathname]
  )

  return {
    pagePic,
    fetchPagePic,
  }
}

type ActionType = ReturnType<typeof fetchPagePicSuccessAction> &
  ReturnType<typeof fetchPagePicFailedAction>

export function pagePicReducer(
  state = {} as MainPageState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_PAGE_PIC_BEGIN:
      return {
        ...state,
        pagePicPending: true,
        pagePicError: null,
      }
    case FETCH_PAGE_PIC_SUCCESS:
      return {
        ...state,
        pagePic: action.data,
        pagePicPending: false,
        pagePicError: null,
      }
    case FETCH_PAGE_PIC_FAILED:
      return {
        ...state,
        pagePicPending: false,
        pagePicError: action.data.error,
      }
    default:
      return state
  }
}
