import { Affix, BackTop, Popover } from 'antd'
import { FC } from 'react'
import './index.scss'

type CommonPicState = {
  commonPic: {
    logo_full: string
    tiktok_qrcode: string
    kwai_qrcode: string
    wechat_channel_qrcode: string
    wechat_mp_qrcode: string
    qrcode: string
  }
}

const FabButton: FC<CommonPicState> = (props) => {
  const { commonPic } = props
  return (
    <Affix
      style={{
        position: 'absolute',
        top: '100px',
        right: '0',
      }}
      className='fab-container'
      offsetTop={100}
    >
      <section className='content'>
        <Popover
          content={
            <img
              src={commonPic.tiktok_qrcode}
              style={{ width: '145px' }}
              alt='tictok'
            />
          }
          placement='left'
        >
          <section className='item'>
            <img src='/static/image/fab/tiktok.png' alt='tiktok' />
            <span>抖音</span>
          </section>
        </Popover>
        <Popover
          content={
            <img
              src={commonPic.kwai_qrcode}
              style={{ width: '145px' }}
              alt='tictok'
            />
          }
          placement='left'
        >
          <section className='item'>
            <img src='/static/image/fab/kwai.png' alt='kwai' />
            <span>快手</span>
          </section>
        </Popover>
        <Popover
          content={
            <img
              src={commonPic.wechat_channel_qrcode}
              style={{ width: '145px' }}
              alt='tictok'
            />
          }
          placement='left'
        >
          <section className='item'>
            <img src='/static/image/fab/wechat-channel.png' alt='channel' />
            <span>微信视频号</span>
          </section>
        </Popover>
        <Popover
          content={
            <img
              src={commonPic.wechat_mp_qrcode}
              style={{ width: '145px' }}
              alt='tictok'
            />
          }
          placement='left'
        >
          <section className='item'>
            <img src='/static/image/fab/wechat-mp.png' alt='mp' />
            <span>微信公众号</span>
          </section>
        </Popover>
        <BackTop className='item' visibilityHeight={0}>
          <img src='/static/image/fab/top.png' alt='top' />
          <span>返回顶部</span>
        </BackTop>
      </section>
    </Affix>
  )
}

export default FabButton
