export const FETCH_MAIN_INFO_BEGIN = 'FETCH_MAIN_INFO_BEGIN'
export const FETCH_MAIN_INFO_SUCCESS = 'FETCH_MAIN_INFO_SUCCESS'
export const FETCH_MAIN_INFO_FAILED = 'FETCH_MAIN_INFO_FAILED'

export const FETCH_BANNER_BEGIN = 'FETCH_BANNER_BEGIN'
export const FETCH_BANNER_SUCCESS = 'FETCH_BANNER_SUCCESS'
export const FETCH_BANNER_FAILED = 'FETCH_BANNER_FAILED'

export const FETCH_PAGE_PIC_BEGIN = 'FETCH_PAGE_PIC_BEGIN'
export const FETCH_PAGE_PIC_SUCCESS = 'FETCH_PAGE_PIC_SUCCESS'
export const FETCH_PAGE_PIC_FAILED = 'FETCH_PAGE_PIC_FAILED'

export const FETCH_COMMON_PIC_BEGIN = 'FETCH_COMMON_PIC_BEGIN'
export const FETCH_COMMON_PIC_SUCCESS = 'FETCH_COMMON_PIC_SUCCESS'
export const FETCH_COMMON_PIC_FAILED = 'FETCH_COMMON_PIC_FAILED'
