import { RouteItem } from '@/models/route'
import SteelPlateDetailPage from './detail'
import SteelPlateHomePage from './home'
import SteelPlateLayout from './layout'
import SteelPlateListPage from './list'

const steelPlateRouteModule: RouteItem = {
  path: 'steel-plate',
  childRoutes: [
    {
      path: 'home',
      name: '威刚板首页',
      component: SteelPlateHomePage,
      isIndex: true,
    },
    {
      path: '',
      component: SteelPlateLayout,
      childRoutes: [
        {
          path: 'list',
          name: '威刚板列表',
          component: SteelPlateListPage,
        },
        {
          path: 'detail',
          name: '威刚板详情',
          component: SteelPlateDetailPage,
        },
      ],
    },
  ],
}

export default steelPlateRouteModule
