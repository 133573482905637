import { useMainInfo } from '@/store/main/fetch-info'
import { useMatchRoute } from '@/utils/hook'
import { Affix, Image, Layout } from 'antd'
import { useState } from 'react'
import './index.scss'

const Header = () => {
  const { mainInfo } = useMainInfo()
  const { childKey, push } = useMatchRoute()
  const [fixedActive, setFixedActive] = useState<boolean>()

  return (
    <Affix
      onChange={setFixedActive}
      style={{
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: 99,
      }}
    >
      <Layout.Header
        className={`site-header ${fixedActive ? 'fixed-active' : ''}`}
        style={{ padding: 0 }}
        onMouseEnter={(_) => setFixedActive(true)}
        onMouseLeave={(_) => setFixedActive(window.scrollY > 0 ? true : false)}
      >
        <section className='max-content'>
          <section className='left-content' onClick={() => push('/')}>
            <Image
              className='logo'
              src={fixedActive ? mainInfo.logo : mainInfo.logoWhite}
              preview={false}
            />
          </section>

          <ul className='right-content'>
            {mainInfo.menu.map((item) => (
              <li
                key={item.id}
                className={`menu-item ${item.id === childKey ? 'active' : ''}`}
                onClick={() => push(item.pageUrl)}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </section>
      </Layout.Header>
    </Affix>
  )
}

export default Header
