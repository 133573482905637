import { RouteItem } from '@/models/route'
import ProjectDetailPage from './detail'
import ProjectLayout from './layout'
import ProjectListPage from './list'

const projectRouteModule: RouteItem = {
  path: 'project',
  component: ProjectLayout,
  childRoutes: [
    {
      path: 'list',
      name: '工程案例列表',
      component: ProjectListPage,
      isIndex: true,
    },
    {
      path: 'detail',
      name: '工程案例详情',
      component: ProjectDetailPage,
    },
  ],
}

export default projectRouteModule
