import { ProdBasePageState } from '@/models/prod-base'
import { fetchProdBaseReducer } from './fetch-prod-base'

const reducers = [fetchProdBaseReducer]

export default function prodBaseReducer(
  state = {} as ProdBasePageState,
  action
) {
  switch (action.type) {
    default:
      break
  }
  return reducers.reduce((s, r) => r(s, action), state)
}
