import { RouteItem } from '@/models/route'
import AboutPage from '@/pages/about'
import ContactPage from '@/pages/contact'
import HomePage from '@/pages/home'
import MainLayout from '@/pages/main'
import newsRouteModule from '@/pages/news'
import ProdBasePage from '@/pages/prod-base'
import projectRouteModule from '@/pages/project'
import steelPlateRouteModule from '@/pages/steel-plate'
import TeamPage from '@/pages/team'
import DemoPage from 'src/pages/demo'
import NotFoundPage from 'src/pages/not-found'

const routes: RouteItem[] = [
  {
    path: '/',
    exact: true,
    component: MainLayout,
    childRoutes: [
      { path: 'home', name: '首页', component: HomePage, isIndex: true },
      { path: 'about', name: '关于中邦', component: AboutPage },
      { path: 'prod-base', name: '生产基地', component: ProdBasePage },
      { path: 'team', name: '技术团队', component: TeamPage },
      { path: 'contact', name: '联系我们', component: ContactPage },
      steelPlateRouteModule,
      projectRouteModule,
      newsRouteModule,
      { path: 'demo', name: '示例', component: DemoPage },
      { path: '*', name: 'NotFoundPage', component: NotFoundPage },
    ],
  },
  // { path: '*', name: 'NotFoundPage', component: NotFoundPage },
]

/**
 * 路由模块下多个路由，带有isIndex标识时设置为默认路由
 * @param route 当前路由表
 * @returns
 */
function handleIndexRoute(route: RouteItem) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return
  }

  const indexRoute = route.childRoutes.find((route) => route.isIndex)
  if (indexRoute) {
    const first = { ...indexRoute }
    first.path = ''
    first.exact = true
    first.autoIndexRoute = true
    route.childRoutes.unshift(first)
  }

  route.childRoutes.forEach(handleIndexRoute)
}

routes.forEach(handleIndexRoute)

export default routes
