import { useFetchContact } from '@/store/contact/hook'
import { usePagePic } from '@/store/main/hook'
import { Col, Row } from 'antd'
import { useEffect } from 'react'
import './index.scss'

const ContactPage = () => {
  const { fetchContact, contactOutput } = useFetchContact()
  const { fetchPagePic, pagePic } = usePagePic()

  useEffect(() => {
    fetchContact()
  }, [fetchContact])

  useEffect(() => {
    fetchPagePic()
  }, [fetchPagePic])

  return (
    <section className='contact-page-container'>
      <section className='contact-content'>
        <h3 className='en-title'>{contactOutput.contact_way.en_title}</h3>
        <p className='cn-title'>{contactOutput.contact_way.cn_title}</p>
        <Row className='list' gutter={40}>
          {contactOutput.contact_way.list.map((item, index) => {
            const icon_pic = () => {
              switch (index + 1) {
                case 1:
                  return (
                    <img src={pagePic.tel_logo} alt='icon' className='icon' />
                  )
                case 2:
                  return (
                    <img src={pagePic.qq_logo} alt='icon' className='icon' />
                  )
                case 3:
                  return (
                    <img src={pagePic.email_logo} alt='icon' className='icon' />
                  )
                case 4:
                  return (
                    <img src={pagePic.addr_logo} alt='icon' className='icon' />
                  )
                default:
                  return <img src={item.icon} alt='icon' className='icon' />
              }
            }
            return (
              <Col span={6} className='item' flex={'auto'}>
                <Row justify={'center'}>{icon_pic()}</Row>
                <Row justify={'center'}>
                  <p className='title'>{item.title}</p>
                </Row>
                <Row justify={'center'}>
                  <p className='text'>{item.text}</p>
                </Row>
              </Col>
            )
          })}
        </Row>
      </section>

      <section className='company-content'>
        <h3 className='en-title'>{contactOutput.company.en_title}</h3>
        <p className='cn-title'>{contactOutput.company.cn_title}</p>
        <iframe
          className='map'
          src={contactOutput.company.addr_bg}
          title='map'
        ></iframe>
      </section>
    </section>
  )
}

export default ContactPage
