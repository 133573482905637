export const FETCH_STEEL_PLATE_HOME_BEGIN = 'FETCH_STEEL_PLATE_HOME_BEGIN'
export const FETCH_STEEL_PLATE_HOME_SUCCESS = 'FETCH_STEEL_PLATE_HOME_SUCCESS'
export const FETCH_STEEL_PLATE_HOME_FAILED = 'FETCH_STEEL_PLATE_HOME_FAILED'

export const FETCH_STEEL_PLATE_LIST_BEGIN = 'FETCH_STEEL_PLATE_LIST_BEGIN'
export const FETCH_STEEL_PLATE_LIST_SUCCESS = 'FETCH_STEEL_PLATE_LIST_SUCCESS'
export const FETCH_STEEL_PLATE_LIST_FAILED = 'FETCH_STEEL_PLATE_LIST_FAILED'

export const FETCH_STEEL_PLATE_CATE_LIST_BEGIN =
  'FETCH_STEEL_PLATE_CATE_LIST_BEGIN'
export const FETCH_STEEL_PLATE_CATE_LIST_FAILED =
  'FETCH_STEEL_PLATE_CATE_LIST_FAILED'
export const FETCH_STEEL_PLATE_CATE_LIST_SUCCESS =
  'FETCH_STEEL_PLATE_CATE_LIST_SUCCESS'

export const FETCH_STEEL_PLATE_DETAIL_BEGIN = 'FETCH_STEEL_PLATE_DETAIL_BEGIN'
export const FETCH_STEEL_PLATE_DETAIL_SUCCESS =
  'FETCH_STEEL_PLATE_DETAIL_SUCCESS'
export const FETCH_STEEL_PLATE_DETAIL_FAILED = 'FETCH_STEEL_PLATE_DETAIL_FAILED'
