import { useFetchProjectDetail } from '@/store/project/hook'
import { useEffect } from 'react'
import './index.scss'

const ProjectDetailPage = () => {
  const { fetchProjectDetail, projectDetail } = useFetchProjectDetail()

  useEffect(() => {
    fetchProjectDetail()
  }, [fetchProjectDetail])

  return (
    <section className='project-detail-page-container'>
      <section className='content'>
        <h3 className='title'>{projectDetail.name}</h3>
        <p className='date'>{projectDetail.date}</p>
        <section
          dangerouslySetInnerHTML={{ __html: projectDetail.detail }}
        ></section>
      </section>
    </section>
  )
}

export default ProjectDetailPage
