import ApiUrl from '@/constants/api-url'
import { SteelPlateDetail, SteelPlateState } from '@/models/steel-plate'
import { useQuery } from '@/utils/hook'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_STEEL_PLATE_DETAIL_BEGIN,
  FETCH_STEEL_PLATE_DETAIL_FAILED,
  FETCH_STEEL_PLATE_DETAIL_SUCCESS,
} from './constant'

const fetchSteelPlateDetailBeginAction = () => ({
  type: FETCH_STEEL_PLATE_DETAIL_BEGIN,
})
const fetchSteelPlateDetailSuccessAction = (data: SteelPlateDetail) => ({
  type: FETCH_STEEL_PLATE_DETAIL_SUCCESS,
  data,
})
const fetchSteelPlateDetailFailedAction = (data: { error: any }) => ({
  type: FETCH_STEEL_PLATE_DETAIL_FAILED,
  data,
})
export const fetchSteelPlateDetailAsyncAction = (id) => {
  return async (dispatch) => {
    dispatch(fetchSteelPlateDetailBeginAction())

    try {
      const { data } = await http.post(ApiUrl.SteelPlateDetail, { id })
      dispatch(fetchSteelPlateDetailSuccessAction(data))
    } catch (error) {
      dispatch(fetchSteelPlateDetailFailedAction({ error }))
    }
  }
}

export function useFetchSteelPlateDetail() {
  const dispatch = useDispatch()
  const id = useQuery().get('articleId')
  const { steelPlateDetail } = useSelector(
    (state: RootStore) => state.steelPlate
  )

  const fetchSteelPlateDetail = useCallback(
    () => dispatch(fetchSteelPlateDetailAsyncAction(id)),
    [dispatch, id]
  )

  return {
    fetchSteelPlateDetail,
    steelPlateDetail,
  }
}

type ActionType = ReturnType<typeof fetchSteelPlateDetailSuccessAction> &
  ReturnType<typeof fetchSteelPlateDetailFailedAction>

export function fetchSteelPlateDetailReducer(
  state = {} as SteelPlateState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_STEEL_PLATE_DETAIL_BEGIN:
      return {
        ...state,
        steelPlateDetailPending: true,
        steelPlateDetailError: null,
      }
    case FETCH_STEEL_PLATE_DETAIL_SUCCESS:
      return {
        ...state,
        steelPlateDetailPending: false,
        steelPlateDetailError: null,
        steelPlateDetail: action.data,
      }
    case FETCH_STEEL_PLATE_DETAIL_FAILED:
      return {
        ...state,
        steelPlateDetailPending: false,
        steelPlateDetailError: action.data.error,
      }
    default:
      return state
  }
}
