import ApiUrl from '@/constants/api-url'
import { SteelPlateHomeOutput, SteelPlateState } from '@/models/steel-plate'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_STEEL_PLATE_HOME_BEGIN,
  FETCH_STEEL_PLATE_HOME_FAILED,
  FETCH_STEEL_PLATE_HOME_SUCCESS,
} from './constant'

const fetchSteelPlateHomeBeginAction = () => ({
  type: FETCH_STEEL_PLATE_HOME_BEGIN,
})
const fetchSteelPlateHomeSuccessAction = (data: SteelPlateHomeOutput) => ({
  type: FETCH_STEEL_PLATE_HOME_SUCCESS,
  data,
})
const fetchSteelPlateHomeFailedAction = (data: { error: any }) => ({
  type: FETCH_STEEL_PLATE_HOME_FAILED,
  data,
})
export const fetchSteelPlateHomeAsyncAction = () => {
  return async (dispatch) => {
    dispatch(fetchSteelPlateHomeBeginAction())

    try {
      const { data } = await http.get(ApiUrl.SteelPlateHome)
      dispatch(fetchSteelPlateHomeSuccessAction(data))
    } catch (error) {
      dispatch(fetchSteelPlateHomeFailedAction({ error }))
    }
  }
}

export function useFetchSteelPlateHome() {
  const dispatch = useDispatch()
  const { steelPlateHome } = useSelector((state: RootStore) => state.steelPlate)

  const fetchSteelPlateHome = useCallback(
    () => dispatch(fetchSteelPlateHomeAsyncAction()),
    [dispatch]
  )

  return {
    steelPlateHome,
    fetchSteelPlateHome,
  }
}

type ActionType = ReturnType<typeof fetchSteelPlateHomeSuccessAction> &
  ReturnType<typeof fetchSteelPlateHomeFailedAction>

export function fetchSteelPlateHomeReducer(
  state = {} as SteelPlateState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_STEEL_PLATE_HOME_BEGIN:
      return {
        ...state,
        steelPlateHomePending: true,
        steelPlateHomeError: null,
      }
    case FETCH_STEEL_PLATE_HOME_SUCCESS:
      return {
        ...state,
        steelPlateHome: action.data,
        steelPlateHomePending: false,
        steelPlateHomeError: null,
      }
    case FETCH_STEEL_PLATE_HOME_FAILED:
      return {
        ...state,
        steelPlateHomePending: false,
        steelPlateHomeError: action.data.error,
      }
    default:
      return state
  }
}
