import { useFetchNewsDetail } from '@/store/news/hook'
import { useEffect } from 'react'
import './index.scss'

const NewsDetailPage = () => {
  const { fetchNewsDetail, newsDetail } = useFetchNewsDetail()

  useEffect(() => {
    fetchNewsDetail()
  }, [fetchNewsDetail])

  return (
    <section className='news-detail-page-container'>
      <section className='content'>
        <h3 className='title'>{newsDetail.name}</h3>
        <p className='info'>
          <span>浏览：{newsDetail.views}次</span>
          <span className='date'>发布时间：{newsDetail.date}</span>
        </p>
        <section
          dangerouslySetInnerHTML={{ __html: newsDetail.detail }}
        ></section>
      </section>
    </section>
  )
}

export default NewsDetailPage
