import ApiUrl from '@/constants/api-url'
import { HomeIndexOutput, HomePageState } from '@/models/home'
import http from '@/utils/http'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import { useCallback } from 'react'
import {
  FETCH_HOME_INDEX_BEGIN,
  FETCH_HOME_INDEX_FAILED,
  FETCH_HOME_INDEX_SUCCESS,
} from './constant'

const fetchHomeIndexBeginAction = () => ({
  type: FETCH_HOME_INDEX_BEGIN,
})
const fetchHomeIndexSuccessAction = (data: HomeIndexOutput) => ({
  type: FETCH_HOME_INDEX_SUCCESS,
  data,
})
const fetchHomeIndexFailedAction = (data: { error: any }) => ({
  type: FETCH_HOME_INDEX_FAILED,
  data,
})
export const fetchHomeIndexAsyncAction = () => {
  return async (dispatch) => {
    dispatch(fetchHomeIndexBeginAction())

    try {
      const { data } = await http.get(ApiUrl.HomeIndex)
      dispatch(fetchHomeIndexSuccessAction(data))
    } catch (error) {
      dispatch(fetchHomeIndexFailedAction({ error }))
    }
  }
}

export function useFetchHomeIndex() {
  const dispatch = useDispatch()
  const { homeIndexOutput } = useSelector((state: RootStore) => state.home)

  const fetchHomeIndex = useCallback(
    () => dispatch(fetchHomeIndexAsyncAction()),
    [dispatch]
  )

  return {
    homeIndexOutput,
    fetchHomeIndex,
  }
}

type ActionType = ReturnType<typeof fetchHomeIndexSuccessAction> &
  ReturnType<typeof fetchHomeIndexFailedAction>

export function fetchHomeIndexReducer(
  state = {} as HomePageState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_HOME_INDEX_BEGIN:
      return {
        ...state,
        homeIndexPending: true,
        homeIndexError: null,
      }
    case FETCH_HOME_INDEX_SUCCESS:
      return {
        ...state,
        homeIndexOutput: action.data,
        homeIndexPending: false,
        homeIndexError: null,
      }
    case FETCH_HOME_INDEX_FAILED:
      return {
        ...state,
        homeIndexPending: false,
        homeIndexError: action.data.error,
      }
    default:
      return state
  }
}
