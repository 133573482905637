import { RouteItem } from '@/models/route'
import NewsDetailPage from './detail'
import NewsLayout from './layout'
import NewsListPage from './list'

const newsRouteModule: RouteItem = {
  path: 'news',
  component: NewsLayout,
  childRoutes: [
    {
      path: 'list',
      name: '新闻资讯',
      component: NewsListPage,
      isIndex: true,
    },
    {
      path: 'detail',
      name: '工程案例详情',
      component: NewsDetailPage,
    },
  ],
}

export default newsRouteModule
