import { AboutPageState } from '@/models/about'
import { fetchAboutReducer } from './fetch-about'

const reducers = [fetchAboutReducer]

export default function aboutReducer(state = {} as AboutPageState, action) {
  switch (action.type) {
    default:
      break
  }
  return reducers.reduce((s, r) => r(s, action), state)
}
