import { useFetchAbout } from '@/store/about/hook'
import { usePagePic } from '@/store/main/hook'
import { Fragment, useEffect } from 'react'
import Timeline from './history'
import './index.scss'

const AboutPage = () => {
  const { fetchAbout, aboutOutput } = useFetchAbout()
  const { fetchPagePic, pagePic } = usePagePic()

  useEffect(() => {
    fetchAbout()
  }, [fetchAbout])

  useEffect(() => {
    fetchPagePic()
  }, [fetchPagePic])

  return (
    <section className='about-page-container'>
      <section className='intro-content'>
        {aboutOutput.intro.map((item, index) => {
          const dom = () => {
            switch (index + 1) {
              case 1:
                return (
                  <Fragment>
                    <section>
                      <p className='en'>NEWS AND INFORMATION</p>
                      <h3 className='cn'>关于深中邦</h3>
                    </section>
                    <p className='text'>{item}</p>
                  </Fragment>
                )
              case 2:
                return <img src={pagePic.about_1} alt='img' />
              case 3:
                return <img src={pagePic.about_2} alt='img' />
              case 4:
                return <p className='text'>{item}</p>
              default:
                return null
            }
          }

          return (
            <section className={`block block-${index + 1}`} key={index}>
              {dom()}
            </section>
          )
        })}
      </section>
      <section className='core-content'>
        <p className='en'>CORE ADVANTAGES</p>
        <h3 className='title'>核心优势</h3>
        <p className='intro'>{aboutOutput.core.intro}</p>
        <section className='num'>
          {aboutOutput.core.num.map((item) => {
            const text1 = item.split('|')[0]
            const text2 = item.split('|')[1]
            return (
              <section className='item' key={item}>
                <span className='text1'>{text1}</span>
                <span className='text2'>{text2}</span>
              </section>
            )
          })}
        </section>
      </section>
      <section className='history-content'>
        <p className='en'>DEVELOPMENT HISTORY</p>
        <h3 className='title'>发展历程</h3>
        <Timeline data={aboutOutput.history} />
      </section>
      <section className='culture-content'>
        <p className='en'>CORPORATE CULTURE</p>
        <h3 className='title'>企业文化</h3>
        <section className='content'>
          <section className='left'>
            <img src={pagePic.about_circle} alt='bg' />
          </section>
          <section className='right'>
            {aboutOutput.culture.intro.map((item) => (
              <section className='item' key={item.id}>
                <section className='intro-title'>{item.title}</section>
                {item.text.map((text, i) => (
                  <p key={i}>{text}</p>
                ))}
              </section>
            ))}
          </section>
        </section>
      </section>
    </section>
  )
}

export default AboutPage
