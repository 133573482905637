import ApiUrl from '@/constants/api-url'
import { CommonPicState, MainPageState } from '@/models/main'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_COMMON_PIC_BEGIN,
  FETCH_COMMON_PIC_FAILED,
  FETCH_COMMON_PIC_SUCCESS,
} from './constant'

const fetchCommonPicBeginAction = () => ({
  type: FETCH_COMMON_PIC_BEGIN,
})
const fetchCommonPicSuccessAction = (data: CommonPicState) => ({
  type: FETCH_COMMON_PIC_SUCCESS,
  data,
})
const fetchCommonPicFailedAction = (data: { error: any }) => ({
  type: FETCH_COMMON_PIC_FAILED,
  data,
})
export const fetchCommonPicAsyncAction = () => {
  return async (dispatch) => {
    dispatch(fetchCommonPicBeginAction())

    try {
      const { data } = await http.post(ApiUrl.CommonPic)

      dispatch(fetchCommonPicSuccessAction(data))
    } catch (error) {
      dispatch(fetchCommonPicFailedAction({ error }))
    }
  }
}

export function useCommonPic() {
  const dispatch = useDispatch()
  const { commonPic } = useSelector((state: RootStore) => state.main)

  const fetchCommonPic = useCallback(
    () => dispatch(fetchCommonPicAsyncAction()),
    [dispatch]
  )

  return {
    commonPic,
    fetchCommonPic,
  }
}

type ActionType = ReturnType<typeof fetchCommonPicSuccessAction> &
  ReturnType<typeof fetchCommonPicFailedAction>

export function commonPicReducer(
  state = {} as MainPageState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_COMMON_PIC_BEGIN:
      return {
        ...state,
        commonPicPending: true,
        commonPicError: null,
      }
    case FETCH_COMMON_PIC_SUCCESS:
      return {
        ...state,
        commonPic: action.data,
        commonPicPending: false,
        commonPicError: null,
      }
    case FETCH_COMMON_PIC_FAILED:
      return {
        ...state,
        commonPicPending: false,
        commonPicError: action.data.error,
      }
    default:
      return state
  }
}
