import ApiUrl from '@/constants/api-url'
import { useQuery } from '@/utils/hook'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  SteelPlateListOutput,
  SteelPlateListParams,
  SteelPlateState,
} from '@/models/steel-plate'
import {
  FETCH_STEEL_PLATE_LIST_BEGIN,
  FETCH_STEEL_PLATE_LIST_FAILED,
  FETCH_STEEL_PLATE_LIST_SUCCESS,
} from './constant'

const fetchSteelPlateListBeginAction = () => ({
  type: FETCH_STEEL_PLATE_LIST_BEGIN,
})
const fetchSteelPlateListSuccessAction = (data: SteelPlateListOutput) => ({
  type: FETCH_STEEL_PLATE_LIST_SUCCESS,
  data,
})
const fetchSteelPlateListFailedAction = (data: { error: any }) => ({
  type: FETCH_STEEL_PLATE_LIST_FAILED,
  data,
})
export const fetchSteelPlateListAsyncAction = (
  params: SteelPlateListParams
) => {
  return async (dispatch) => {
    dispatch(fetchSteelPlateListBeginAction())

    try {
      const { data } = await http.get(ApiUrl.SteelPlateList, { params })
      dispatch(fetchSteelPlateListSuccessAction(data))
    } catch (error) {
      dispatch(fetchSteelPlateListFailedAction({ error }))
    }
  }
}

export function useFetchSteelPlateList() {
  const dispatch = useDispatch()
  const cateId = useQuery().get('cateId')
  const { steelPlateListOutput } = useSelector(
    (state: RootStore) => state.steelPlate
  )

  const fetchSteelPlateList = useCallback(
    (params?: SteelPlateListParams) =>
      dispatch(
        fetchSteelPlateListAsyncAction({
          cate_id: +cateId!,
          current_page: params?.current_page || 1,
          per_page: params?.per_page || 7,
        })
      ),
    [dispatch, cateId]
  )

  return {
    steelPlateListOutput,
    fetchSteelPlateList,
    cateId: +cateId!,
  }
}

type ActionType = ReturnType<typeof fetchSteelPlateListSuccessAction> &
  ReturnType<typeof fetchSteelPlateListFailedAction>

export function fetchSteelPlateListReducer(
  state = {} as SteelPlateState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_STEEL_PLATE_LIST_BEGIN:
      return {
        ...state,
        steelPlateListPending: true,
        steelPlateListError: null,
      }
    case FETCH_STEEL_PLATE_LIST_SUCCESS:
      return {
        ...state,
        steelPlateListPending: false,
        steelPlateListError: null,
        steelPlateListOutput: action.data,
      }
    case FETCH_STEEL_PLATE_LIST_FAILED:
      return {
        ...state,
        steelPlateListPending: false,
        steelPlateListError: action.data.error,
      }
    default:
      return state
  }
}
