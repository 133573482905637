import { useMainInfo } from '@/store/main/hook'
import { Image, Layout } from 'antd'
import './index.scss'

const Footer = () => {
  const { mainInfo } = useMainInfo()

  return (
    <Layout.Footer className='site-footer' style={{ padding: 0 }}>
      <section className='top-content'>
        <section className='max-content'>
          <section className='left-content'>
            <section className='info'>
              <h1 className='groupname'>{mainInfo.groupname}</h1>
              {/* <section className='tel'>
                <a href='tel:400-027-888'>{mainInfo.tel[0]}</a>
                <span> / </span>
                <a href='tel:027-5665888'>{mainInfo.tel[1]}</a>
              </section> */}
              <section className='address'>地址：{mainInfo.addr}</section>
              <section className='zipcode'>邮编：{mainInfo.zipcode}</section>
            </section>
          </section>

          <section className='right-content'>
            <section className='qrcode'>
              <Image className='qrcode' src={mainInfo.qrcode} preview={false} />
              <p className='qrtext'>扫描二维码关注我们</p>
            </section>
          </section>
        </section>
      </section>

      <section className='bottom-content'>
        <a href='http://beian.miit.gov.cn/'>{mainInfo.beian}</a>
      </section>
    </Layout.Footer>
  )
}

export default Footer
