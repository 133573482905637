import { useFetchSteelPlateDetail } from '@/store/steel-plate/hook'
import { useEffect } from 'react'
import './index.scss'

const SteelPlateDetailPage = () => {
  const { fetchSteelPlateDetail, steelPlateDetail } = useFetchSteelPlateDetail()

  useEffect(() => {
    fetchSteelPlateDetail()
  }, [fetchSteelPlateDetail])

  return (
    <section className='steel-plate-detail-page-container'>
      <section className='content'>
        <h3 className='title'>{steelPlateDetail.name}</h3>
        <p className='date'>{steelPlateDetail.date}</p>
        <section
          dangerouslySetInnerHTML={{ __html: steelPlateDetail.detail }}
        ></section>
      </section>
    </section>
  )
}

export default SteelPlateDetailPage
