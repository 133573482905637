import ApiUrl from '@/constants/api-url'
import { ProdBaseOutput, ProdBasePageState } from '@/models/prod-base'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_PROD_BASE_BEGIN,
  FETCH_PROD_BASE_FAILED,
  FETCH_PROD_BASE_SUCCESS,
} from './constant'

const fetchProdBaseBeginAction = () => ({
  type: FETCH_PROD_BASE_BEGIN,
})
const fetchProdBaseSuccessAction = (data: ProdBaseOutput) => ({
  type: FETCH_PROD_BASE_SUCCESS,
  data,
})
const fetchProdBaseFailedAction = (data: { error: any }) => ({
  type: FETCH_PROD_BASE_FAILED,
  data,
})
export const fetchProdBaseAsyncAction = () => {
  return async (dispatch) => {
    dispatch(fetchProdBaseBeginAction())

    try {
      const { data } = await http.get(ApiUrl.ProdBase)
      dispatch(fetchProdBaseSuccessAction(data))
    } catch (error) {
      dispatch(fetchProdBaseFailedAction({ error }))
    }
  }
}

export function useFetchProdBase() {
  const dispatch = useDispatch()
  const { prodBaseOutput } = useSelector((state: RootStore) => state.prodBase)

  const fetchProdBase = useCallback(
    () => dispatch(fetchProdBaseAsyncAction()),
    [dispatch]
  )

  return {
    prodBaseOutput,
    fetchProdBase,
  }
}

type ActionType = ReturnType<typeof fetchProdBaseSuccessAction> &
  ReturnType<typeof fetchProdBaseFailedAction>

export function fetchProdBaseReducer(
  state = {} as ProdBasePageState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_PROD_BASE_BEGIN:
      return {
        ...state,
        prodBasePending: true,
        prodBaseError: null,
      }
    case FETCH_PROD_BASE_SUCCESS:
      return {
        ...state,
        prodBaseOutput: action.data,
        prodBasePending: false,
        prodBaseError: null,
      }
    case FETCH_PROD_BASE_FAILED:
      return {
        ...state,
        prodBasePending: false,
        prodBaseError: action.data.error,
      }
    default:
      return state
  }
}
