import ApiUrl from '@/constants/api-url'
import { PagerInput } from '@/models/common'
import { NewsListOutput, NewsPageState } from '@/models/news'
import http from '@/utils/http'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_NEWS_LIST_BEGIN,
  FETCH_NEWS_LIST_FAILED,
  FETCH_NEWS_LIST_SUCCESS,
} from './constant'

const fetchNewsListBeginAction = () => ({
  type: FETCH_NEWS_LIST_BEGIN,
})
const fetchNewsListSuccessAction = (data: NewsListOutput) => ({
  type: FETCH_NEWS_LIST_SUCCESS,
  data,
})
const fetchNewsListFailedAction = (data: { error: any }) => ({
  type: FETCH_NEWS_LIST_FAILED,
  data,
})
export const fetchNewsListAsyncAction = (params: PagerInput) => {
  return async (dispatch) => {
    dispatch(fetchNewsListBeginAction())

    try {
      const { data } = await http.get(ApiUrl.NewsList, { params })
      dispatch(fetchNewsListSuccessAction(data))
    } catch (error) {
      dispatch(fetchNewsListFailedAction({ error }))
    }
  }
}

export function useFetchNewsList() {
  const dispatch = useDispatch()
  const [params, setParams] = useState<PagerInput>({
    current_page: 1,
    per_page: 10,
  })
  const { newsListOutput } = useSelector((state: RootStore) => state.news)

  const fetchNewsList = useCallback(
    () => dispatch(fetchNewsListAsyncAction(params)),
    [dispatch, params]
  )

  return {
    newsListOutput,
    fetchNewsList,
    params,
    setParams,
  }
}

type ActionType = ReturnType<typeof fetchNewsListSuccessAction> &
  ReturnType<typeof fetchNewsListFailedAction>

export function fetchNewsListReducer(
  state = {} as NewsPageState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_NEWS_LIST_BEGIN:
      return {
        ...state,
        newsListPending: true,
        newsListError: null,
      }
    case FETCH_NEWS_LIST_SUCCESS:
      return {
        ...state,
        newsListPending: false,
        newsListError: null,
        newsListOutput: action.data,
      }
    case FETCH_NEWS_LIST_FAILED:
      return {
        ...state,
        newsListPending: false,
        newsListError: action.data.error,
      }
    default:
      return state
  }
}
