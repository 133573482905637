import { combineReducers } from 'redux'
import aboutReducer from './about/reducer'
import contactReducer from './contact/reducer'
import demoReducer from './demo/reducer'
import homeReducer from './home/reducer'
import mainReducer from './main/reducer'
import newsReducer from './news/reducer'
import prodBaseReducer from './prod-base/reducer'
import projectReducer from './project/reducer'
import steelPlateReducer from './steel-plate/reducer'
import teamReducer from './team/reducer'

const rootReducers = combineReducers({
  demo: demoReducer,
  main: mainReducer,
  home: homeReducer,
  about: aboutReducer,
  steelPlate: steelPlateReducer,
  prodBase: prodBaseReducer,
  team: teamReducer,
  contact: contactReducer,
  project: projectReducer,
  news: newsReducer,
})

export type RootStore = ReturnType<typeof rootReducers>

export default rootReducers
