import { useFetchProdBase } from '@/store/prod-base/hook'
import { usePagePic } from '@/store/main/hook'
import { Fragment, useEffect } from 'react'
import './index.scss'

const ProdBasePage = () => {
  const { fetchProdBase, prodBaseOutput } = useFetchProdBase()
  const { fetchPagePic, pagePic } = usePagePic()

  useEffect(() => {
    fetchProdBase()
  }, [fetchProdBase])

  useEffect(() => {
    fetchPagePic()
  }, [fetchPagePic])

  return (
    <section className='prod-base-page-container'>
      {prodBaseOutput.zb ? (
        <section className='zb-base'>
          <section className='intro-content'>
            {prodBaseOutput.zb.intro.map((item, index) => {
              const dom = () => {
                switch (index + 1) {
                  case 1:
                    return (
                      <Fragment>
                        <section>
                          <p className='en'>{prodBaseOutput.zb?.en_title}</p>
                          <h3 className='cn'>{prodBaseOutput.zb?.cn_title}</h3>
                        </section>
                        <p className='text'>{item}</p>
                      </Fragment>
                    )
                  case 2:
                    return <img src={pagePic.zj_intro_1} alt='img' />
                  case 3:
                    return <img src={pagePic.zj_intro_2} alt='img' />
                  case 4:
                    return <p className='text'>{item}</p>
                  default:
                    return null
                }
              }

              return (
                <section className={`block block-${index + 1}`} key={index}>
                  {dom()}
                </section>
              )
            })}
          </section>
          <section className='base-content'>
            {prodBaseOutput.zb.base_imgs.map((item, index) => {
              const pic_key = index + 3
              return (
                <section className='img' key={index}>
                  <img src={pagePic['zj_intro_' + pic_key]} alt='img' />
                </section>
              )
            })}
          </section>
          <section className='addr-content'>
            <section className='left'>
              <p className='title'>基地地址：</p>
              {prodBaseOutput.zb.address[0] ? (
                <Fragment>
                  <p className='desc'>
                    {prodBaseOutput.zb.address[0].split('|')[0]}
                  </p>
                  <p className='desc'>
                    {prodBaseOutput.zb.address[0].split('|')[1]}
                  </p>
                </Fragment>
              ) : null}
            </section>
            <section className='right'>
              <img src={prodBaseOutput.zb.address[1]} alt='banner' />
            </section>
          </section>
        </section>
      ) : null}
      {prodBaseOutput.zj ? (
        <section className='zj-base'>
          <section className='intro-content'>
            {prodBaseOutput.zj.intro.map((item, index) => {
              const dom = () => {
                switch (index + 1) {
                  case 1:
                    return (
                      <Fragment>
                        <section>
                          <p className='en'>{prodBaseOutput.zj?.en_title}</p>
                          <h3 className='cn'>{prodBaseOutput.zj?.cn_title}</h3>
                        </section>
                        <p className='text'>{item}</p>
                      </Fragment>
                    )
                  case 2:
                    return <img src={pagePic.zj_intro_1} alt='img' />
                  case 3:
                    return <img src={pagePic.zj_intro_2} alt='img' />
                  case 4:
                    return <p className='text'>{item}</p>
                  default:
                    return null
                }
              }

              return (
                <section className={`block block-${index + 1}`} key={index}>
                  {dom()}
                </section>
              )
            })}
          </section>
          <section className='base-content'>
            {prodBaseOutput.zj.base_imgs.map((item, index) => {
              const pic_key = index + 3
              return (
                <section className='img' key={index}>
                  <img src={pagePic['zj_intro_' + pic_key]} alt='img' />
                </section>
              )
            })}
          </section>
          <section className='addr-content'>
            <section className='left'>
              <p className='title'>基地地址：</p>
              {prodBaseOutput.zj.address[0] ? (
                <Fragment>
                  <p className='desc'>
                    {prodBaseOutput.zj.address[0].split('|')[0]}
                  </p>
                  <p className='desc'>
                    {prodBaseOutput.zj.address[0].split('|')[1]}
                  </p>
                </Fragment>
              ) : null}
            </section>
            <section className='right'>
              <iframe src={prodBaseOutput.zj.address[1]} title='map'></iframe>
            </section>
          </section>
        </section>
      ) : null}
    </section>
  )
}

export default ProdBasePage
