import ApiUrl from '@/constants/api-url'
import { MainInfoState, MainPageState } from '@/models/main'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_MAIN_INFO_BEGIN,
  FETCH_MAIN_INFO_FAILED,
  FETCH_MAIN_INFO_SUCCESS,
} from './constant'

const fetchMainInfoBeginAction = () => ({
  type: FETCH_MAIN_INFO_BEGIN,
})
const fetchMainInfoSuccessAction = (data: MainInfoState) => ({
  type: FETCH_MAIN_INFO_SUCCESS,
  data,
})
const fetchMainInfoFailedAction = (data: { error: any }) => ({
  type: FETCH_MAIN_INFO_FAILED,
  data,
})
export const fetchMainInfoAsyncAction = () => {
  return async (dispatch) => {
    dispatch(fetchMainInfoBeginAction())

    try {
      const { data } = await http.get(ApiUrl.MainInfo)

      dispatch(fetchMainInfoSuccessAction(data))
    } catch (error) {
      dispatch(fetchMainInfoFailedAction({ error }))
    }
  }
}

export function useMainInfo() {
  const dispatch = useDispatch()
  const { mainInfo } = useSelector((state: RootStore) => state.main)

  const fetchMainInfo = useCallback(
    () => dispatch(fetchMainInfoAsyncAction()),
    [dispatch]
  )

  return {
    mainInfo,
    fetchMainInfo,
  }
}

type ActionType = ReturnType<typeof fetchMainInfoSuccessAction> &
  ReturnType<typeof fetchMainInfoFailedAction>

export function mainInfoReducer(
  state = {} as MainPageState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_MAIN_INFO_BEGIN:
      return {
        ...state,
        mainInfoPending: true,
        mainInfoError: null,
      }
    case FETCH_MAIN_INFO_SUCCESS:
      return {
        ...state,
        mainInfo: action.data,
        mainInfoPending: false,
        mainInfoError: null,
      }
    case FETCH_MAIN_INFO_FAILED:
      return {
        ...state,
        mainInfoPending: false,
        mainInfoError: action.data.error,
      }
    default:
      return state
  }
}
