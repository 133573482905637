import ApiUrl from '@/constants/api-url'
import { SteelPlateCateItem, SteelPlateState } from '@/models/steel-plate'
import http from '@/utils/http'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../reducers'
import {
  FETCH_STEEL_PLATE_CATE_LIST_BEGIN,
  FETCH_STEEL_PLATE_CATE_LIST_FAILED,
  FETCH_STEEL_PLATE_CATE_LIST_SUCCESS,
} from './constant'

const fetchSteelPlateCateListBeginAction = () => ({
  type: FETCH_STEEL_PLATE_CATE_LIST_BEGIN,
})
const fetchSteelPlateCateListSuccessAction = (data: SteelPlateCateItem[]) => ({
  type: FETCH_STEEL_PLATE_CATE_LIST_SUCCESS,
  data,
})
const fetchSteelPlateCateListFailedAction = (data: { error: any }) => ({
  type: FETCH_STEEL_PLATE_CATE_LIST_FAILED,
  data,
})
export const fetchSteelPlateCateListAsyncAction = () => {
  return async (dispatch) => {
    dispatch(fetchSteelPlateCateListBeginAction())

    try {
      const { data } = await http.get(ApiUrl.SteelPlateCateList)
      dispatch(fetchSteelPlateCateListSuccessAction(data))
    } catch (error) {
      dispatch(fetchSteelPlateCateListFailedAction({ error }))
    }
  }
}

export function useFetchSteelPlateCateList() {
  const dispatch = useDispatch()
  const { steelPlateCateList } = useSelector(
    (state: RootStore) => state.steelPlate
  )

  const fetchSteelPlateCateList = useCallback(
    () => dispatch(fetchSteelPlateCateListAsyncAction()),
    [dispatch]
  )

  return {
    steelPlateCateList,
    fetchSteelPlateCateList,
  }
}

type ActionType = ReturnType<typeof fetchSteelPlateCateListSuccessAction> &
  ReturnType<typeof fetchSteelPlateCateListFailedAction>

export function fetchSteelPlateCateListReducer(
  state = {} as SteelPlateState,
  action: ActionType
) {
  switch (action.type) {
    case FETCH_STEEL_PLATE_CATE_LIST_BEGIN:
      return {
        ...state,
        steelPlateCateListPending: true,
        steelPlateCateListError: null,
      }
    case FETCH_STEEL_PLATE_CATE_LIST_SUCCESS:
      return {
        ...state,
        steelPlateCateList: action.data,
        steelPlateCateListPending: false,
        steelPlateCateListError: null,
      }
    case FETCH_STEEL_PLATE_CATE_LIST_FAILED:
      return {
        ...state,
        steelPlateCateListPending: false,
        steelPlateCateListError: action.data.error,
      }
    default:
      return state
  }
}
